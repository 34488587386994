<template>
  <a v-if="isExternalLink" :href="isFullURL || withTrailingSlashPath" :target="targetForExternalLink" :class="class">
    <slot></slot>
  </a>
  <nuxt-link v-else :to="withTrailingSlashPath" :external="external" :class="class">
    <slot></slot>
  </nuxt-link>
</template>

<script>
import { addTrailingSlash } from '@/utils/url-action'
import { useRoute } from 'nuxt/app'
const LINKBAL_ID_DOMAIN = /id\.linkbal\.com/
const SIGN_UP_LINK = /accounts\/sign_up/
const ZENDESK_DOMAIN = /linkbal\.zendesk\.com/
const FULL_URL_REGEXP = /^http/

export default {
  props: {
    to: { type: [String, Object], default: '#', required: true },
    options: { type: Object, default: null, required: false }, // options to handle some special links (not used now),
    external: { type: Boolean, default: false, required: false },
    class: { type: String, default: '', required: false }
  },
  computed: {
    withTrailingSlashPath() {
      if (typeof this.to === 'string') return this.to ? addTrailingSlash(this.to) : '#'
      try {
        const router = useRouter()
        const targetRoute = router.resolve(this.to)
        if (targetRoute.matched.length === 0) return '#'

        const { name, path, params, query, hash } = targetRoute
        return {
          name,
          path: /\/$/.test(path) ? path : `${path}/`,
          params,
          query,
          hash
        }
      } catch {
        return '#'
      }
    },
    isExternalLink() {
      return /^(http|\/lp\/|\/ivery\/|\/kekkon\/)/.test(this.withTrailingSlashPath)
    },
    targetForExternalLink() {
      if (SIGN_UP_LINK.test(this.withTrailingSlashPath)) {
        return '_self'
      }
      return ![LINKBAL_ID_DOMAIN, ZENDESK_DOMAIN].some(elm => elm.test(this.withTrailingSlashPath)) && '_blank'
    },
    isFullURL() {
      return FULL_URL_REGEXP.test(this.to) ? this.to : null
    }
  }
}
</script>
