import { ABTestConfigList } from './abTestConfig'
import type { ABTestConfig } from './abTestConfig'
import { ABTestGAEventList } from './gaEventConfig'
import type { AB_TEST_GA_EVENT_TARGET } from './gaEventConfig'
import { useABTest } from './useABTest'
import GTM from '@/utils/tracking/gtm'

export function sendABTestGAEvent(targetEvent: AB_TEST_GA_EVENT_TARGET, customLabel?: string) {
  // テスト期間中のABテストのみを所得
  const activeABTests = ABTestConfigList.filter(config => {
    const { isAPattern, isBPattern } = useABTest(config)
    return isAPattern.value || isBPattern.value
  })

  activeABTests.forEach((config: ABTestConfig) => {
    const abTestGAEvent = ABTestGAEventList.find(item => item.ab_test_name === config.test_name)
    const targetGaEvent = abTestGAEvent?.events?.find(event => event.target_event === targetEvent)
    if (!abTestGAEvent || !targetGaEvent) return

    const { isAPattern } = useABTest(config)

    // If customLabel exists, format the label as "A_{customLabel}" or "B_{customLabel}"
    let label: string | undefined
    if (customLabel) {
      label = isAPattern.value ? `A_${customLabel}` : `B_${customLabel}`
    } else {
      label = isAPattern.value ? targetGaEvent.Alabel : targetGaEvent.Blabel
    }

    // const label = isAPattern.value ? targetGaEvent.Alabel : targetGaEvent.Blabel
    if (!label) return
    GTM.sendEventToGa({
      category: abTestGAEvent.category,
      action: targetGaEvent.action,
      label: label,
      value: undefined
    })
  })
}
