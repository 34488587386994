<template>
  <div class="container">
    <div class="containerBox">
      <div class="commonMargin">
        <h2 class="marginT5 marginB4 guide-title">
          初めての{{ firstPartyGuide.title === '街コン' ? firstPartyGuide.title + 'ガイド' : firstPartyGuide.title + 'パーティーガイド' }}
        </h2>
        <div :class="{ marginB4: $store.isPC }" class="content content-guide">
          <custom-nuxt-link :to="firstPartyGuide.url">
            <common-lazy-load-image
              :image-url="firstPartyGuide.banner_url"
              :alt="`初めて${firstPartyGuide.title}パーティーに参加する方へ`"
              class="commonBanner content-image"
            />
          </custom-nuxt-link>
        </div>
        <h2 class="marginT5 marginB4 guide-title">街コンジャパンの特徴</h2>
        <div :class="{ marginB4: $store.isPC }" class="content content-guide">
          <custom-nuxt-link to="/beginner/#mj_features">
            <common-lazy-load-image :image-url="bnrMachiconFeatures" alt="machicon JAPANの特徴" class="commonBanner content-image" />
          </custom-nuxt-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import bnrGuideKoikatsu from '/assets/images/bnr_guide_koikatsuparty.jpeg'
import bnrGuideKonkatsu from '/assets/images/bnr_guide_konkatsuparty.jpeg'
import bnrMachiconFeatures from '/assets/images/bnr_machicon_features.png'
import bnrGuide from '/assets/images/bnr_guide.png'

const FIRST_KOIKATSU_GUIDE = { title: '街コン・恋活', url: '/beginner/koikatsuparty/', banner_url: bnrGuideKoikatsu }
const FIRST_KONKATSU_GUIDE = { title: '婚活', url: '/beginner/konkatsuparty/', banner_url: bnrGuideKonkatsu }
const FIRST_MACHICON_GUIDE = { title: '街コン', url: '/beginner/', banner_url: bnrGuide }

const props = defineProps({
  partyGuide: { type: String, default: '', required: true }
})

const firstPartyGuide = computed(() => {
  if (props.partyGuide === 'konkatsu') {
    return FIRST_KONKATSU_GUIDE
  } else if (props.partyGuide === 'koikatsu') {
    return FIRST_KOIKATSU_GUIDE
  } else {
    return FIRST_MACHICON_GUIDE
  }
})
</script>

<style lang="scss" scoped>
.guide-title {
  @include pc {
    text-align: center;
  }
}

.content {
  display: flex-column;
  gap: 5rem;
  @include pc {
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
  }
  &-image {
    text-align: center;
    height: auto;
    @include sp {
      width: 100%;
    }
    aspect-ratio: 16/5;
    box-shadow: $spread-box-shadow;
    border: 1px solid #c6c6c6;
    border-radius: 1.2rem;
  }
  &-guide {
    @include pc {
      margin-bottom: 20px;
    }

    a {
      width: 100%;
      overflow: hidden;
      text-align: center;
    }
  }
}

.container {
  display: block;
  &Box {
    width: 100%;
  }
}

.commonMargin {
  margin-left: $spCommonMargin;
  margin-right: $spCommonMargin;
}
</style>
