import { useMainStore } from '@/store'

import PageDetector from '@/utils/PageDetector'
import SearchLink from '@/utils/search/SearchLink'
import SearchParams from '@/utils/search/SearchParams'
import { addTrailingSlash } from '@/utils/url-action'
import Cookies from 'mj-cookie'
import { isPastDateRedirect } from './redirectDate.global'

const FETCH_BANNER_HEADER_PERIOD = 30 * 60 // seconds (30 minutes)

const STATIC_CONTENTS_FILTER_PAGE_TYPES = {
  'filter[feature_tag]': 'features',
  'filter[event_type]': 'categories'
}

// refresh banner header every 30 minutes
// only execute on client side
const fetchBannerHeader = store => {
  if (import.meta.server) return
  const lastUpdatedAt = store.layout_data.banner_header.last_updated_at
  const diff = Math.floor((Date.now() - lastUpdatedAt) / 1000)
  if (!lastUpdatedAt || diff < FETCH_BANNER_HEADER_PERIOD) return
  store.getBannerHeader()
}

const fetchStaticContents = (store, params) => {
  const targetFilter = Object.keys(STATIC_CONTENTS_FILTER_PAGE_TYPES).find(key => params[key])
  if (targetFilter) {
    const slug = params[targetFilter].split(',')[0]
    const pageType = STATIC_CONTENTS_FILTER_PAGE_TYPES[targetFilter]
    store.getStaticContents(`/${pageType}/${slug}/`)
  } else {
    store.static_contents = {}
  }
}

const savePreviousPathToTopSearchMjPage = (store, fromRoute) => {
  const pageDetector = new PageDetector(fromRoute)
  if (pageDetector.isTopPage || pageDetector.isSearchPage) {
    store.previous_path_to_top_search_mj_page = fromRoute.fullPath
  }
}

const savePreviousPageBeforeMjCl = (store, fromRoute) => {
  const pageDetector = new PageDetector(fromRoute)
  if (!pageDetector.isParticipantsPage && !pageDetector.isMarriagesPage && !pageDetector.isDatingEventsPage) {
    store.previous_page_before_mjcl_page = fromRoute.fullPath
  }
}

const redirectToWeekendPage = (store, route, navigateTo) => {
  const pageDetector = new PageDetector(route)
  const searchLink = new SearchLink(store, route)
  if (pageDetector.isThisWeekendPage) {
    return [true, navigateTo(searchLink.weekendDateEventListPath)]
  }

  if (pageDetector.isNextWeekendPage) {
    return [true, navigateTo(searchLink.nextWeekendDateEventListPath)]
  }
  return [false, null]
}

const checkQuestionnaireRemind = (store, route) => {
  if (route.name === 'questionnaire' || !store.$state.auth_info) return
  const questionnaireRemindedAt = Cookies.get('questionnaireRemind')
  if (!questionnaireRemindedAt) {
    store.dispatch('questionnaireRemind')
  }
}

export default defineNuxtRouteMiddleware((to, from) => {
  if (to.path.includes('/dates/today')) {
    return navigateTo({ path: `/dates/${today()}`, query: to.query })
  }

  if (to.path.includes('/dates/tomorrow')) {
    return navigateTo({ path: `/dates/${tomorrow()}`, query: to.query })
  }

  const mainStore = useMainStore()

  // リダイレクトチェーンを防ぐため、過去日付の場合、TrailingSlashを追加しない.
  const datePathMatch = to.path.match(/^\/dates\/(\d{4})(?:\/(\d{1,2}))?(?:\/(\d{1,2}))?$/)
  const isPastDate = datePathMatch && isPastDateRedirect(datePathMatch[1], datePathMatch[2], datePathMatch[3], new Date())

  if (!isPastDate && addTrailingSlash(to.fullPath) !== to.fullPath) {
    return navigateTo(addTrailingSlash(to.fullPath))
  }

  const [needRedirect, redirectNav] = redirectToWeekendPage(mainStore, to, navigateTo)
  if (needRedirect) return redirectNav

  const apiSearchParams = new SearchParams(mainStore, to).apiSearchParams
  const pageDetector = new PageDetector(to)

  fetchBannerHeader(mainStore)
  fetchStaticContents(mainStore, apiSearchParams)

  if (from) {
    // TODO Maybe from allways setted
    mainStore.previous_page_path = from.fullPath
    savePreviousPageBeforeMjCl(mainStore, from)
    savePreviousPathToTopSearchMjPage(mainStore, from)
  }
  // reset some store value
  if (mainStore.$state.modal.name !== 'QuestionnaireRemindModal') mainStore.MODAL(null)
  mainStore.error_code = null

  // clear flash_message if current route is not flash_message target path
  if (mainStore.flash_message && mainStore.flash_message.target_path && mainStore.flash_message.target_path !== to.path) {
    mainStore.FLASH_MESSAGE(null)
  }

  // if current page is not search detail page, it not show event list header so I reset is_tab_changed value is false
  if (!pageDetector.isSearchDetailPage) {
    mainStore.is_tab_changed = false
  }

  checkQuestionnaireRemind(mainStore, to)
})
