import PageDetector from '@/utils/PageDetector'
import { eventOrganizedDate, currentYear } from '@/utils/date'
import { descriptionForCsp } from '@/utils/eventPageDescription'
import { findRegionByPrefectureSlug, isPlaceName, findRegionBySlug, findPrefectureBySlug, findAreaGroupBySlug, findAreaBySlug } from '@/models/places'
import EventModel from '@/models/events'
import moment from 'mj-moment'
import {
  MJ_TOP_TITLE,
  CHRISTMAS_PREFIX,
  OSHOUGATSU_PREFIX,
  TIME_START_DISPLAY_CHRISTMAS_PREFIX,
  TIME_START_DISPLAY_OSHOUGATSU_PREFIX,
  TIME_FINISH_DISPLAY_OSHOUGATSU_PREFIX
} from '@/utils/constants/commons'
import isNoIndexPages from '@/utils/CheckNoIndexPages'

const NOT_FOUND_PAGE_TITLE = 'ページが見つかりませんでした'
const DEFAULT_TITLE = MJ_TOP_TITLE
const DEFAULT_DESCRIPTION =
  '【街コン掲載数/実績No.1】10年以上の実績と圧倒的な街コン/恋活・婚活パーティー掲載数の街コンジャパン。はじめての方でも簡単にイベントを検索でき、安心してご参加いただけます。'
const DEFAULT_KEYWORD =
  '街コン,街こん,メガ合コン,まちコン,まちこん,マチコン,合コン,コンパ,イベント,パーティー,交流,大規模,地域活性化,東京,名古屋,大阪'

import DEFAULT_OGP_IMAGE from '/assets/images/mj_ogp.png'

const FEATURES_TOP_TITLE = '街コン・婚活パーティー特集一覧'

const TEMPLATE_INTL_TITLE = 'machicon JAPAN'
const TEMPLATE_SHORT = '街コンジャパン'
const TEMPLATE_LONG = '街コン・婚活パーティーなら街コンジャパン'
const TEMPLATE_MACHICON = '街コンジャパン-街コン公式サイト'
const TEMPLATE_ZENKOKU_MACHICON = '街コンジャパン-全国の街コン公式サイト-'
const TEMPLATE_ZENKOKU_DEAI = '全国の出会い公式サイト-街コンジャパン'
const TEMPLATE_ZENKOKU_GOCON = '全国の合コン・コンパ公式サイト-街コンジャパン-'
const TEMPLAGE_FEATURE_WANTTOLOVE = '街コンジャパン-全国の出逢い公式サイト-'
const TEMPLAGE_FEATURE_FIFTIES = '独身女性・男性の方にもおすすめ-街コンジャパン-'
const TEMPLAGE_FEATURE_ENDOFHEISEI = '平成最後に出会うなら-街コンジャパン-'
const TEMPLAGE_FEATURE_INTERNATIONAL = '街コンジャパンで外国人と交流・婚活（お見合い）'
const TEMPLAGE_FEATURE_CROSS_INDUSTRY_PARTY = '街コンジャパン‐全国の異業種交流会公式サイト‐'
const TEMPLAGE_FEATURE_AROUND_FORTIES = '婚活パーティー情報多数！街コンジャパン'
const TEMPLAGE_FEATURE_FOTIES_KONKATSU = '独身女性・男性の方にもおすすめ-街コンジャパン-'

export default class HeadMeta {
  constructor(context, apiSearchParams) {
    this.context = context
    this.route = context.$route
    this.router = context.$router
    this.params = { ...this.route.query, ...this.route.params }
    this.pageDetector = new PageDetector(this.route)
    this.apiSearchParams = apiSearchParams

    this.setSearchConditions()
  }

  setSearchConditions() {
    const searchConditions = this.context.$store.$state.search_page.search_conditions
    const eventTypes = this.apiSearchParams['filter[event_type]'] || ''

    // event関連情報の初期値をセット
    this.interest = null
    this.subInterest = null
    this.featureTag = null
    this.promoter = null
    this.customSearch = null
    this.activityTheme = null
    this.activityGenre = null
    this.organizer = null
    this.eventType = null

    // 地域情報をセット
    if (this.apiSearchParams['filter[area]'] && !this.params.area) this.params.area = this.apiSearchParams['filter[area]'].split(',')[0]
    if (this.params.region) this.region = findRegionBySlug(this.params.region)
    if (this.params.prefecture) this.prefecture = findPrefectureBySlug(this.params.prefecture)
    if (this.params.area_group) this.areaGroup = findAreaGroupBySlug(this.params.area_group)
    if (this.params.area) this.area = findAreaBySlug(this.params.area)
    this.place = this.getPlaceModel()

    // event関連情報の初期値をセット
    if (searchConditions) {
      if (searchConditions.interests) this.interest = searchConditions.interests[0]
      if (searchConditions.sub_interest) this.subInterest = searchConditions.sub_interest
      if (searchConditions.feature_tag) this.featureTag = searchConditions.feature_tag
      if (searchConditions.promoter) this.promoter = searchConditions.promoter
      if (searchConditions.custom_searches) this.customSearch = searchConditions.custom_searches[0]
      if (searchConditions.activity_theme) this.activityTheme = searchConditions.activity_theme
      if (searchConditions.activity_genre) this.activityGenre = searchConditions.activity_genre
      if (searchConditions.organizer) this.organizer = searchConditions.organizer
    }
    this.eventType = eventTypes.includes(',') ? '' : eventTypes

    // イベント一覧ページの判定
    this.isEventListPage = this.context.isEventListPage

    // イベントリストの総数
    this.page = parseInt(this.params.page) || 1
    this.totalCount = parseInt(this.context.totalCount) || 0
    this.pageText = this.page > 1 ? `（${this.page}ページ目）` : ''
  }

  get titleTemplate() {
    if (this.pageDetector.isInternationalizationPage) return `${this.context.$useI18('area_page.title')} ｜ ${TEMPLATE_INTL_TITLE}`
    return this.pageDetector.isTopPage || this.isMensPassOrKonkatsuFreePassEvent() ? null : `%s | ${this.titleTemplateText()}`
  }

  get title() {
    if (this.isNotFoundPage()) return NOT_FOUND_PAGE_TITLE
    if (this.pageDetector.isOrganizerReviewPage) return `${this.context.organizer.name}の口コミ・評判`
    if (this.pageDetector.isTopPage) return DEFAULT_TITLE
    if (this.pageDetector.isBizInquiryPage) return 'お問い合わせ'
    if (this.pageDetector.isQuestionnairesPage) return 'アンケート'
    if (this.pageDetector.isMarketInsightsTopPage) return '調査レポート一覧'
    if (this.pageDetector.isMarketInsightsDetailPage) return this.context.announcement.title
    if (this.pageDetector.isMediumTopPage) return 'メディア紹介情報'
    if (this.pageDetector.isInformationTopPage) return 'お知らせ一覧'
    if (this.pageDetector.isInformationDetailPage) return this.context.announcement.title
    if (this.pageDetector.isPurchasesTopPage) return 'チケット一覧'
    if (this.pageDetector.isEventBookmarksPage) return '気になるリスト一覧'
    if (this.pageDetector.isAreasTopPage)
      return this.pageDetector.isFirstPage
        ? '全国の街コン・婚活パーティーの出会い一覧'
        : `全国の街コン・婚活パーティーの出会い一覧 (${this.page}ページ目)`
    if (this.pageDetector.isInterestTopPage) return '趣味コン（趣味活）の出会い一覧'
    if (this.pageDetector.isActivityTopPage) return '体験・アクティビティーカテゴリー一覧'
    if (this.pageDetector.isSimilarEventsPage) return '類似する街コン（おすすめ）'
    if (this.pageDetector.isSearchDetailPage) return '街コンこだわり検索'
    if (this.pageDetector.isEventDetailPage) {
      const eventDetailTitle = this.context.meta.title ? this.context.meta.title : this.context.event.title
      let eventStatusText = ''
      if (this.context.event.status === 'cancelled' || moment().isAfter(this.context.event.end_at)) {
        eventStatusText = '※販売終了※ '
      }
      return eventStatusText + eventDetailTitle
    }
    if (this.apiSearchParams['filter[keyword]']) {
      const suffixText = this.pageDetector.isSearchEventsPage ? '検索結果' : '街コン・婚活パーティー検索結果'
      return `「${this.apiSearchParams['filter[keyword]']}」の${suffixText}${this.pageText}`
    }
    if (this.pageDetector.isSearchEventsPage) return `${this.searchPageTitle()}の街コン・婚活パーティーの出会い一覧（${currentYear()}）`
    if (this.pageDetector.isRegionRankingPage) return `${this.context.regionName}の街コン・婚活パーティーおすすめランキング`
    if (this.pageDetector.isPrefectureRankingPage) return this.titleForPrefectureRankingPage(this.context.prefectureName)
    if (this.pageDetector.isAreaGroupRankingPage) return this.titleForRankingPage(this.context.areaGroupName)
    if (this.pageDetector.isAreaRankingPage) return this.titleForRankingPage(this.context.areaName)
    if (this.pageDetector.isFeaturesTopPage) return this.page > 1 ? `${FEATURES_TOP_TITLE} - Part ${this.page}` : FEATURES_TOP_TITLE
    if (this.pageDetector.isCategoryDetailPage) {
      const suffix = this.eventType === 'konkatsu' ? '検索サイト' : '一覧'
      const pageNumber = this.page > 1 ? `(${this.page}ページ目)` : ''
      return EventModel.getEventTypeName(this.eventType) + suffix + pageNumber
    }
    if (this.pageDetector.isStaticPage) return this.context.headTitle ? this.context.headTitle : this.context.title

    const titleAreaContidion = this.titleAreaCondition()
    const type = this.titleTypeCondition()
    const titleText = this.titleText(this.dateCondition(), titleAreaContidion, type)
    if (this.pageDetector.isFixedEventsPage) return titleText
    if (this.pageDetector.isPromoterEventsPage) return this.page > 1 ? `${titleText} (${this.page}ページ目)` : titleText
    if (
      this.pageDetector.isSyumiplusPage ||
      this.pageDetector.isCustomSearchPage ||
      this.pageDetector.isAreasCustomSearchPage ||
      this.pageDetector.isAreasAreaCustomSearchPage ||
      this.pageDetector.isRegionsCustomSearchPage ||
      this.pageDetector.isAreasCategoriesPage ||
      this.pageDetector.isRegionsCategoriesPage
    )
      return this.page > 1 ? `${titleText} (${this.page}ページ目)` : titleText
    if (
      this.pageDetector.isDatesPageWithDayMonthYearAreas ||
      this.pageDetector.isDatesPageWithDayMonthYearCategories ||
      this.pageDetector.isDatesPageWithDayMonthYearRegions
    )
      return this.page > 1 ? `${titleText} (${this.page}ページ目)` : titleText
    if (this.pageDetector.isDatesPageWithDayMonthYearAreasCategories || this.pageDetector.isDatesPageWithDayMonthYearRegionsCategories)
      return this.page > 1 ? `${titleText} (${this.page}ページ目)` : titleText
    if (
      this.pageDetector.isDatesPageWithDayMonthYearFeatures ||
      this.pageDetector.isDatesPageWithDayMonthYearAreasFeatures ||
      this.pageDetector.isDatesPageWithDayMonthYearRegionsFeatures
    )
      return this.page > 1 ? `${titleText} (${this.page}ページ目)` : titleText
    if (this.pageDetector.isInterestsPage) return this.page > 1 ? `${titleText} (${this.page}ページ目)` : titleText
    if (this.pageDetector.isDatesPages && this.pageDetector.isDatesPageWithDayMonthYear) return `${titleText} (${this.page}ページ目)`
    return this.page > 1 ? `${titleText} - Part ${this.page}` : titleText
  }

  get description() {
    if (this.isNotFoundPage()) return null
    if (this.pageDetector.isInternationalizationPage) return this.context.$useI18('area_page.meta_description')
    return this.pageDetector.isTopPage ? this.defaultDescription() : this.descriptionText()
  }

  get keywords() {
    if (this.isNotFoundPage()) return null
    return this.pageDetector.isTopPage ? DEFAULT_KEYWORD : this.keywordText()
  }

  get robots() {
    if (isNoIndexPages(this.context, this.apiSearchParams)) return 'noindex,follow'
    if (this.noIndexPromoterPage()) return 'noindex,nofollow'
    return undefined
  }

  get viewport() {
    const maxinumScale = this.context.$store.$state.ios ? 1 : 5
    return `width=device-width, initial-scale=1, user-scalable=1, maximum-scale=${maxinumScale}, minimum-scale=1`
  }

  get ogp() {
    if (this.isNotFoundPage() || this.isMensPassOrKonkatsuFreePassEvent()) return null
    if (this.pageDetector.isTopPage || this.pageDetector.isEventDetailPage) {
      const title = this.pageDetector.isTopPage ? DEFAULT_TITLE : this.context.event.title
      const imageUrl = this.pageDetector.isTopPage ? DEFAULT_OGP_IMAGE : this.context.event.eyecatch_image.url
      return {
        'twitter:card': 'summary_large_image',
        'twitter:site': '@machiconjp',
        'twitter:title': title,
        'twitter:description': title,
        'twitter:image': imageUrl,
        'og:title': title,
        'og:type': this.pageDetector.isTopPage ? 'website' : 'article',
        'og:url': this.canonical,
        'og:image': imageUrl
      }
    }
    return undefined
  }

  generateTitleForRanking(name) {
    return this.params.category ? `${name}の${this.eventTypeText(this.eventType)}` : `${name}の街コン・婚活パーティー`
  }

  titleForRankingPage(name) {
    return `${this.generateTitleForRanking(name)}おすすめランキング`
  }

  titleForPrefectureRankingPage(name) {
    return `${this.generateTitleForRanking(name)}ランキング`
  }

  eventTypeText(eventType) {
    if (!eventType) return '街コン'
    if (
      eventType === 'konkatsu' &&
      (this.pageDetector.isPrefectureRankingPage || this.pageDetector.isAreaGroupRankingPage || this.pageDetector.isAreaRankingPage)
    )
      return '婚活パーティー'

    return EventModel.getEventTypeName(eventType)
  }

  titleAreaConditionCsp() {
    return this.place ? `${this.place.name}の` : ''
  }

  titleAreaCondition() {
    if (!this.place) return ''
    if (this.params.region) return `${this.place.name}地方の`
    return `${this.place.name}の`
  }

  getPlaceModel() {
    if (this.params.area) return findAreaBySlug(this.params.area)
    if (this.params.area_group) return findAreaGroupBySlug(this.params.area_group)
    if (this.params.prefecture) return findPrefectureBySlug(this.params.prefecture)
    if (this.params.region) return findRegionBySlug(this.params.region)
  }

  dateCondition() {
    const yyyymmdd = 'YYYY年MM月DD日'
    const yyyymm = 'YYYY年MM月'
    const yyyy = 'YYYY年'
    const filterFrom = this.apiSearchParams['filter[start_at_or_end_at][gte]'] || this.apiSearchParams['filter[start_at][gte]']
    const filterTo = this.apiSearchParams['filter[start_at_or_end_at][lt]'] || this.apiSearchParams['filter[start_at][lt]']

    if (filterFrom && filterTo) {
      const dateFrom = moment(filterFrom)
      const dateTo = moment(filterTo)

      if (dateTo.diff(dateFrom, 'days') <= 1) return dateFrom.format(yyyymmdd)
      if (dateTo.diff(dateFrom, 'days') <= 31) return dateFrom.format(yyyymm)

      return dateFrom.format(yyyy)
    }

    return ''
  }

  titleTypeCondition() {
    const titleTypeArr = []
    const deaiSuffix = '・出会いイベント'

    if (this.activityGenre || this.activityTheme) titleTypeArr.push(this.activityGenre ? this.activityGenre.name : this.activityTheme.name)
    else if (this.interest)
      titleTypeArr.push(this.eventType ? `${this.interest.name}の${EventModel.getEventTypeName(this.eventType)}` : this.interest.name)
    else if (this.pageDetector.isInterestTopPage || this.apiSearchParams['filter[have_interest]']) titleTypeArr.push('趣味コン')
    else if (this.eventType) titleTypeArr.push(this.eventTypeText(this.eventType))
    else if (this.pageDetector.isSimpleAreaPage || this.pageDetector.isSimpleRegionPage) titleTypeArr.push('街コン・婚活パーティー')
    else if (this.pageDetector.isDatesPage || (this.pageDetector.isDatesPages && this.pageDetector.isDatesPageWithDayMonthYear))
      titleTypeArr.push('街コン・婚活パーティーの出会い')
    else if (this.pageDetector.isDatesPageWithDayMonthYearAreas || this.pageDetector.isDatesPageWithDayMonthYearRegions)
      titleTypeArr.push('街コン・婚活パーティーの出会い')
    else if (
      /^\/areas\/(.+?)\/(.+?)\/(.+?)\/?(?:page\/\d{1,3})?/.test(this.route.path) &&
      ['saitama', 'tokyo', 'aichi', 'nagano', 'fukushima', 'aomori', 'ehime'].includes(this.params.prefecture) &&
      ['ag1199', 'ag1302', 'ag1308', 'ag2301', 'ag2000', 'ag0700', 'ag0200', 'ag3800'].includes(this.params.area_group) &&
      ['kumagaya', 'yurakucho', 'omotesando', 'sakae', 'ueda', 'iwaki', 'hachinohe', 'matsuyama', 'toyota'].includes(this.params.area)
    )
      titleTypeArr.push('街コン（本日参加可能）')
    else titleTypeArr.push('街コン（全イベント）出会い')

    if (!this.pageDetector.isInterestActivityTopPage && this.pageDetector.isInterestActivityDetailPage) {
      titleTypeArr.push(deaiSuffix)
    } else if (this.interest && !this.eventType) {
      titleTypeArr.push('の出会い')
    }

    return titleTypeArr.join('')
  }

  titleText(dateText, titleAreaCondition, type) {
    const needAreaPrefix =
      this.pageDetector.isDatesPageWithDayMonthYearAreas ||
      this.pageDetector.isDatesPageWithDayMonthYearRegions ||
      this.pageDetector.isDatesPageWithDayMonthYearAreasCategories ||
      this.pageDetector.isDatesPageWithDayMonthYearRegionsCategories ||
      this.pageDetector.isDatesPageWithDayMonthYearFeatures ||
      this.pageDetector.isDatesPageWithDayMonthYearAreasFeatures ||
      this.pageDetector.isDatesPageWithDayMonthYearRegionsFeatures ||
      this.pageDetector.isInterestsPage
    const dateSuffix = titleAreaCondition === '' || needAreaPrefix ? 'の' : ' '
    const formatedDateText = dateText === '' ? '' : dateText + dateSuffix
    const specialCaseCond =
      /^\/areas\/(.+?)\/(.+?)\/(.+?)\/?(?:page\/\d{1,3})?/.test(this.route.path) &&
      ['saitama', 'tokyo', 'aichi', 'nagano', 'fukushima', 'aomori', 'ehime'].includes(this.params.prefecture) &&
      ['ag1199', 'ag1302', 'ag1308', 'ag2301', 'ag2000', 'ag2302', 'ag0700', 'ag0200', 'ag3800'].includes(this.params.area_group) &&
      ['kumagaya', 'ginza', 'yurakucho', 'omotesando', 'sakae', 'toyohashi', 'ueda', 'iwaki', 'hachinohe', 'matsuyama', 'toyota'].includes(
        this.params.area
      )
    const goToKonkastuCond1 =
      /^\/areas\/(.+?)\/(.+?)\/(.+?)\/?(?:page\/\d{1,3})?/.test(this.route.path) &&
      [
        'tokyo',
        'chiba',
        'osaka',
        'kumamoto',
        'hokkaido',
        'shizuoka',
        'mie',
        'hyogo',
        'saitama',
        'iwate',
        'okayama',
        'aichi',
        'ehime',
        'ishikawa',
        'tokushima'
      ].includes(this.params.prefecture) &&
      [
        'ag1302',
        'ag1304',
        'ag1299',
        'ag2301',
        'ag2799',
        'ag4300',
        'ag0199',
        'ag2299',
        'ag2400',
        'ag2899',
        'ag1199',
        'ag0300',
        'ag2202',
        'ag2302',
        'ag3800',
        'ag2400',
        'ag1700',
        'ag3399',
        'ag3600'
      ].includes(this.params.area_group) &&
      [
        'ginza',
        'shinjuku',
        'funabashi',
        'nagareyama-city',
        'sakae',
        'takatsuki-city',
        'amakusa-city',
        'muroran-city',
        'kikugawa-city',
        'iga-city',
        'ono',
        'okegawa-city',
        'oshu-city',
        'hamamatsu',
        'toyota',
        'saijo-city',
        'yokkaichi',
        'kanazawa',
        'kazo-city',
        'daito-city',
        'setouchi-city',
        'kaifu-sta'
      ].includes(this.params.area)
    const goToKonkastuCond2 = /^\/areas\/(.+?)\/?(?:page\/\d{1,3})?/.test(this.route.path) && ['okayama'].includes(this.params.prefecture)
    const goToKonkastuCond3 =
      /^\/regions\/(.+?)\/?(?:page\/\d{1,3})?/.test(this.route.path) && ['kanto'].includes(this.params.region) && !this.route.params.category
    if ((goToKonkastuCond1 || goToKonkastuCond2) && this.eventType === 'konkatsu') return `${formatedDateText + titleAreaCondition + type}一覧`
    if (goToKonkastuCond3 && this.eventType === 'konkatsu') return `【Go to 婚活】${formatedDateText + titleAreaCondition + type}一覧`
    if (
      this.pageDetector.isDatesPageWithDayMonthYearFeatures ||
      this.pageDetector.isDatesPageWithDayMonthYearAreasFeatures ||
      this.pageDetector.isDatesPageWithDayMonthYearRegionsFeatures
    )
      return `${formatedDateText + titleAreaCondition + this.featureTag.name}一覧`
    if (specialCaseCond && this.featureTag && this.featureTag.slug === 'gocon') return this.featureTagTitle()
    if (specialCaseCond && this.customSearch)
      return (
        this.titleAreaConditionCsp() +
        `${this.customSearch.head_title ? this.customSearch.head_title : this.customSearch.name}の街コン・婚活パーティーの出会い一覧`
      )
    if (specialCaseCond) return `${formatedDateText + titleAreaCondition + type}一覧`
    if (this.promoter) {
      if (this.promoter.head_title) return `${titleAreaCondition}${this.promoter.head_title}`
      return `${formatedDateText}${titleAreaCondition}${this.organizer}主催のイベント一覧`
    }
    if (this.params.prefecture && this.featureTag && this.featureTag.slug === 'gocon') return this.featureTagTitle()
    if (this.featureTag && (this.params.area || this.params.region)) return `${formatedDateText}${titleAreaCondition}${this.featureTag.name}一覧`
    if (this.featureTag && this.featureTag.slug === 'tomokatsu')
      return `${titleAreaCondition}友達作り・友達探し（社会人サークル・オフ会）の友活イベント一覧`
    if (this.featureTag && this.featureTag.slug === 'fifties') return '50代（恋愛・結婚）・恋活イベント一覧'
    if (this.featureTag && this.featureTag.slug === 'wantto-getmarried')
      return this.featureTag.head_title ? this.featureTag.head_title : this.featureTag.name
    if (this.featureTag && this.featureTag.slug === 'around40') return this.featureTag.head_title ? this.featureTag.head_title : this.featureTag.name
    if (this.featureTag && !this.interest)
      return `${titleAreaCondition}${this.featureTag.head_title ? this.featureTag.head_title : this.featureTag.name}一覧`
    if (this.customSearch)
      return (
        this.titleAreaConditionCsp() +
        `${this.customSearch.head_title ? this.customSearch.head_title : this.customSearch.name}の街コン・婚活パーティーの出会い一覧`
      )
    if (this.pageDetector.isFixedEventsPage) return `${titleAreaCondition}開催確定イベント一覧`

    // custom event type
    if (this.apiSearchParams['filter[event_type]'] === 'activity,interest') return `${formatedDateText + titleAreaCondition}趣味コン・出会い一覧`

    if (this.eventType === 'konkatsu' && this.pageDetector.isInterestTopPage) return `${type}一覧`
    if (this.eventType === 'konkatsu' && this.interest) return formatedDateText + titleAreaCondition + type
    if (/{^\/interests\/?$/.test(this.route.path)) return `${formatedDateText + titleAreaCondition + type}・出会い一覧`
    if (this.pageDetector.isSpecialPrefecturePartyPage) return `${dateText + titleAreaCondition + type}・パーティー一覧`

    return `${formatedDateText + titleAreaCondition + type}一覧`
  }

  titleTemplateText() {
    if (
      this.pageDetector.isCustomSearchPage ||
      this.pageDetector.isAreasCustomSearchPage ||
      this.pageDetector.isAreasAreaCustomSearchPage ||
      this.pageDetector.isRegionsCustomSearchPage
    )
      return TEMPLATE_SHORT
    if (this.pageDetector.isKonkatsuEventsPages) return TEMPLATE_SHORT
    if (
      (this.pageDetector.isCategoryDetailPage && !this.pageDetector.isFirstPage) ||
      this.pageDetector.isAreasCategoriesPage ||
      this.pageDetector.isRegionsCategoriesPage
    )
      return TEMPLATE_LONG
    if (this.pageDetector.isFixedEventsPage) return TEMPLATE_LONG
    if (this.pageDetector.isEventDetailPage) return TEMPLATE_LONG
    if (this.pageDetector.isDatesPage || (this.pageDetector.isDatesPages && this.pageDetector.isDatesPageWithDayMonthYear)) return TEMPLATE_SHORT
    if (
      this.pageDetector.isAreaGroupRankingPage ||
      this.pageDetector.isAreaRankingPage ||
      this.pageDetector.isPrefectureRankingPage ||
      this.pageDetector.isRegionRankingPage
    ) {
      if (this.isPurposeSearchPage()) return TEMPLATE_LONG
      return TEMPLATE_SHORT
    }
    if (
      this.pageDetector.isDatesPageWithDayMonthYearAreasCategories ||
      this.pageDetector.isDatesPageWithDayMonthYearRegionsCategories ||
      this.params.category === 'party'
    )
      return TEMPLATE_LONG
    if (this.pageDetector.isDatesPageWithDayMonthYearAreas || this.pageDetector.isDatesPageWithDayMonthYearRegions) return TEMPLATE_SHORT
    if (this.pageDetector.isDatesPageWithDayMonthYearCategories) return TEMPLATE_SHORT
    if (
      this.pageDetector.isDatesPageWithDayMonthYearFeatures ||
      this.pageDetector.isDatesPageWithDayMonthYearAreasFeatures ||
      this.pageDetector.isDatesPageWithDayMonthYearRegionsFeatures
    )
      return TEMPLATE_SHORT
    if (this.pageDetector.isPromoterEventsPage) return TEMPLATE_SHORT
    if (this.pageDetector.isSimpleAreaPage) {
      return TEMPLATE_SHORT
    }
    if (this.pageDetector.isSimpleRegionPage) {
      return TEMPLATE_SHORT
    }
    if (
      this.pageDetector.isAreasDetailPage &&
      [
        'fukushima',
        'tokushima',
        'ehime',
        'ishikawa',
        'tottori',
        'iwate',
        'aomori',
        'yamanashi',
        'akita',
        'fukui',
        'okinawa',
        'yamagata',
        'toyama',
        'gifu'
      ].includes(this.params.prefecture)
    )
      return TEMPLATE_SHORT
    if (/^\/areas\/(.+?)\/features\/gocon\/(page\/\d{1,3}\/)?$/.test(this.route.path)) return TEMPLATE_ZENKOKU_GOCON
    if (
      /^\/areas\/(.+?)\/(.+?)\/(.+?)\/?(?:page\/\d{1,3})?$/.test(this.route.path) &&
      ['saitama', 'tokyo', 'aichi', 'nagano', 'fukushima', 'aomori', 'ehime'].includes(this.params.prefecture) &&
      ['ag1199', 'ag1302', 'ag1308', 'ag2301', 'ag2000', 'ag2302', 'ag0700', 'ag0200', 'ag3800'].includes(this.params.area_group) &&
      ['kumagaya', 'ginza', 'yurakucho', 'omotesando', 'sakae', 'toyohashi', 'ueda', 'iwaki', 'hachinohe', 'matsuyama', 'toyota'].includes(
        this.params.area
      )
    )
      return TEMPLATE_MACHICON
    if (this.pageDetector.isFeaturesEventsPage) {
      if (this.params.feature_tag === 'wanttolove') return TEMPLAGE_FEATURE_WANTTOLOVE
      if (this.params.feature_tag === 'fifties') return TEMPLAGE_FEATURE_FIFTIES
      if (this.params.feature_tag === 'endofheisei') return TEMPLAGE_FEATURE_ENDOFHEISEI
      if (this.params.feature_tag === 'international') return TEMPLAGE_FEATURE_INTERNATIONAL
      if (this.params.feature_tag === 'cross-industry-party') return TEMPLAGE_FEATURE_CROSS_INDUSTRY_PARTY
      if (this.params.feature_tag === 'wantto-getmarried') return TEMPLATE_SHORT
      if (this.params.feature_tag === 'around40') return TEMPLAGE_FEATURE_AROUND_FORTIES
      if (this.params.feature_tag === 'foties-konkatsu') return TEMPLAGE_FEATURE_FOTIES_KONKATSU
      if (this.params.feature_tag) return TEMPLATE_LONG
      return TEMPLATE_SHORT
    }
    if (this.pageDetector.isInterestActivityDetailPage) return TEMPLATE_ZENKOKU_DEAI
    if (this.pageDetector.isInterestsPage) return TEMPLATE_LONG
    if (this.pageDetector.isActivityDetailPage) return TEMPLATE_SHORT // TODO なぜ、これだけ、pageDetector がなかった？
    if (this.pageDetector.isAreasTopPage) return TEMPLATE_SHORT
    if (this.eventType) {
      if (this.eventType === 'jibunmigaki') return TEMPLATE_LONG
      if (this.eventType === 'party') return TEMPLATE_LONG
      return `全国の${EventModel.getEventTypeName(this.eventType)}公式サイト‐街コンジャパン-`
    }
    if (this.featureTag && this.featureTag.slug === 'tomokatsu') return TEMPLATE_LONG
    if (this.pageDetector.isFeaturesTopPage) return TEMPLATE_SHORT
    return TEMPLATE_ZENKOKU_MACHICON
  }

  isPurposeSearchPage() {
    return (
      this.eventType === 'konkatsu' ||
      this.eventType === 'party' ||
      this.eventType === 'jibunmigaki' ||
      this.pageDetector.isInterestsPage ||
      this.pageDetector.isTomokatsuPage ||
      this.params.feature_tag === 'counseling'
    )
  }

  descriptionForSyumiplusPage() {
    const placeName = this.place ? `${this.place.name}の` : ''
    if (this.featureTag.slug === 'syumiplus')
      return `【${placeName}趣味プラス】のイベントをご紹介${this.pageText}☆ 休日は予定があるし、会社帰りなら参加出来るのに…当日でも間に合う！仕事帰りにふらっと参加出来るイベントを集めました！1人参加の方沢山います★アフター5を充実させませんか？`
    return `【${placeName}${this.featureTag.name.replace(/\s/g, '')}】のイベントをご紹介${this.pageText}☆ 休日は予定があるし、会社帰りなら参加出来るのに…当日でも間に合う！仕事帰りにふらっと参加出来るイベントを集めました！1人参加の方沢山います★アフター5を充実させませんか？`
  }

  prefixText() {
    if (moment().isBetween(TIME_START_DISPLAY_CHRISTMAS_PREFIX, TIME_START_DISPLAY_OSHOUGATSU_PREFIX)) return CHRISTMAS_PREFIX
    if (moment().isBetween(TIME_START_DISPLAY_OSHOUGATSU_PREFIX, TIME_FINISH_DISPLAY_OSHOUGATSU_PREFIX)) return OSHOUGATSU_PREFIX
    return ''
  }

  defaultDescription() {
    return this.prefixText() + DEFAULT_DESCRIPTION
  }

  searchPageTitle() {
    return typeof this.context.searchPageTitle === 'string' ? `${this.context.searchPageTitle}${this.pageText}` : this.pageText
  }

  descriptionText() {
    const placeName = this.place ? this.place.name : '全国'

    if (this.noDescriptionPages()) return ''
    if (this.pageDetector.isTopPage) return this.defaultDescription()
    if (this.pageDetector.isStaticPage) return this.context.metaDescription
    if (this.customSearch) return descriptionForCsp(this.pageDetector, this.customSearch, this.title, this.page)
    if (/(\/areas\/hokkaido\/ag0101\/features\/gocon?)|(\/areas\/aichi\/ag2301\/features\/gocon)/.test(this.route.path))
      return `【合コン・コンパ特集】${placeName}で開催されている合コン・コンパイベントをお探しいただけます。合コン・コンパイベントは、個室、少人数での男女の出会いを求める人にぴったりのイベントです。企画はさまざまで、着席スタイルでゆっくり会話を楽しむスタイルや、少人数グループで料理やゴルフなどの体験をとおして知り合う企画などバリエーションも豊富です。`
    if (
      /^\/areas\/(.+?)\/features\/gocon(?:page\/\d{1,3})?$/.test(this.route.path) &&
      ['osaka', 'tokyo', 'chiba', 'hiroshima', 'kyoto', 'fukuoka'].includes(this.params.prefecture)
    )
      return `【合コン・コンパ特集】${placeName}で開催されている合コン・コンパイベントをお探しいただけます。合コン・コンパイベントは、個室、少人数での男女の出会いを求める人にぴったりのイベントです。企画はさまざまで、着席スタイルでゆっくり会話を楽しむスタイルや、少人数グループで料理やゴルフなどの体験をとおして知り合う企画などバリエーションも豊富です。`
    if (this.pageDetector.isEventDetailPage) return (this.context.meta && this.context.meta.description) || ''
    if (this.featureTag && this.featureTag.slug.includes('syumiplus')) return this.descriptionForSyumiplusPage()
    if (this.featureTag && this.featureTag.slug === 'gocon') return this.featureTag.meta_description.replace('全国', placeName)
    if (this.featureTag && !(['walking', 'movie'].includes(this.featureTag.slug) && this.route.path.includes('interests')))
      return this.featureTagDescription(this.featureTag, this.searchPageTitle())
    if (this.promoter) {
      const descriptionPromoter = this.promoter.meta_description
        ? this.promoter.meta_description
        : `街コンや婚活パーティー・お見合いパーティーなど、様々な出会いのイベントを開催中。バリエーションも豊富なのでご希望のイベントがきっと見つかります。${this.organizer}主催イベントをお探しの方はページからお探しください。`
      return `【${this.title}】${descriptionPromoter}`
    }
    if (this.pageDetector.isRegionRankingPage)
      return `【${placeName}エリアのおすすめ街コンをご紹介】人気の一人参加OKの街コンから20代限定の街コンなど、バリエーションも豊富にお探しいただけます。${placeName}エリアの街コンをお探しの方は＜${placeName}エリアの街コンランキング＞ページからお探しください。`
    if (/\/prefecture_rankings\/.*\/categories\/konkatsu/.test(this.route.path))
      return `【${placeName}の婚活パーティー・お見合いパーティーランキング】昨日人気だったイベントをランキング形式で掲載。本気度の高い真剣な出会いを求める方や、お見合いスタイルの真面目なパーティーなど様々な出会いのイベントをお探しいただけます。${placeName}の婚活パーティーをお探しなら【${placeName}の婚活パーティーランキング】ページからお探しください。`
    if (this.pageDetector.isPrefectureRankingPage || this.pageDetector.isAreaGroupRankingPage || this.pageDetector.isAreaRankingPage) {
      const eventTypeText = this.eventType ? EventModel.getEventTypeName(this.eventType) : '街コン・趣味コンなど'
      return `${placeName}の${eventTypeText}から昨日人気だったイベントをランキング！人気すぎて売り切れてしまっていたらゴメンナサイ！`
    }
    if (this.pageDetector.isFeaturesTopPage)
      return '今が旬のおすすめイベント特集からシーズン別特集、ジャンル別特集など、日本最大級の街コン・パーティー情報の総合ポータルサイト街コンジャパンならではのバリエーション豊富なラインナップでイベントをご紹介！'
    if (this.activityTheme) return this.activityTheme.description
    if (this.pageDetector.isAreasInterestsTopPage) {
      const displayAreaText = this.pageDetector.isPrefInterestsTopPage ? placeName : `${placeName}（${this.prefecture.name}）`
      return `【${displayAreaText}の趣味コン掲載中${this.pageText}】】趣味活・趣味コンとは、共通の趣味を持った人たちが集まる出会いのイベントです。共通の趣味だから会話も弾むし、繋がりを持てるのが趣味コンの特徴です。街コンジャパンでは、アニコン（アニメ）、映画、ゲーム、スポーツ、アウトドア、料理など、様々な出会いのイベントをお探しいただけます。${placeName}の趣味コンをお探しの方は【${placeName}の趣味コン】ページからお探しください。`
    }
    if (this.pageDetector.isDatesPageWithYearInterestsBus) {
      return `人気のバスツアーと婚活をセットにした婚活バスツアー（お見合いバスツアー）の検索サイトです。通常の婚活イベントと比較して、一緒にいる時間が長いため、ゆっくりと話すことができ、ツアーを通して相手の人となりをしっかり見られるのが特徴です。`
    }
    if (this.pageDetector.isDatesPageWithYearInterests) {
      return `【${this.params.year}年開催の全国の趣味コン掲載中${this.pageText}】】趣味活・趣味コンとは、共通の趣味を持った人たちが集まる出会いのイベントです。共通の趣味だから会話も弾むし、繋がりを持てるのが趣味コンの特徴です。街コンジャパンでは、アニコン（アニメ）、映画、ゲーム、スポーツ、アウトドア、料理など、様々な出会いのイベントをお探しいただけます。${this.params.year}年開催の全国の趣味コンをお探しの方は【${this.params.year}年開催の全国の趣味コン】一覧ページからお探しください。`
    }
    if (this.pageDetector.isDatesPageWithYearMonthInterests) {
      return `【${this.params.year}年${this.params.month}月開催の全国の趣味コンを掲載中${this.pageText}】】趣味活・趣味コンとは、共通の趣味を持った人たちが集まるおすすめの出会いイベントです。趣味がない方もここなら趣味友達が見つかっちゃうかも。街コンジャパンでは、アニコン（アニメ）、映画、ゲーム、スポーツ、インドア、アウトドア、料理など、大人なあなたにもぴったりの出会いのイベントをお探しいただけます。趣味コン・趣味友をお探しの方は【${this.params.year}年${this.params.month}月開催の全国の趣味コン】一覧ページからお探しください。`
    }
    if (this.pageDetector.isDatesPageWithYearRegionsInterests) {
      return `【${this.params.year}年開催の${placeName}の趣味コン掲載中${this.pageText}】】趣味活・趣味コンとは、共通の趣味を持った人たちが集まる出会いのイベントです。共通の趣味だから会話も弾むし、繋がりを持てるのが趣味コンの特徴です。街コンジャパンでは、アニコン（アニメ）、映画、ゲーム、スポーツ、アウトドア、料理など、様々な出会いのイベントをお探しいただけます。${this.params.year}年開催の${placeName}の趣味コンをお探しの方は【${this.params.year}年開催の${placeName}の趣味コン】一覧ページからお探しください。`
    }
    if (this.pageDetector.isDatesPageWithYearMonthRegionsInterests) {
      return `【${this.params.year}年${this.params.month}月開催の${placeName}の趣味コンを掲載中${this.pageText}】】趣味活・趣味コンとは、共通の趣味を持った人たちが集まるおすすめの出会いイベントです。趣味がない方もここなら趣味友達が見つかっちゃうかも。街コンジャパンでは、アニコン（アニメ）、映画、ゲーム、スポーツ、インドア、アウトドア、料理など、大人なあなたにもぴったりの出会いのイベントをお探しいただけます。趣味コン・趣味友をお探しの方は【${this.params.year}年${this.params.month}月開催の${placeName}の趣味コン】一覧ページからお探しください。`
    }
    if (this.pageDetector.isDatesPageWithYearPrefectureInterests) {
      return `【${this.params.year}年開催の${placeName}の趣味コン掲載中${this.pageText}】】趣味活・趣味コンとは、共通の趣味を持った人たちが集まる出会いのイベントです。共通の趣味だから会話も弾むし、繋がりを持てるのが趣味コンの特徴です。街コンジャパンでは、アニコン（アニメ）、映画、ゲーム、スポーツ、アウトドア、料理など、様々な出会いのイベントをお探しいただけます。${this.params.year}年開催の${placeName}の趣味コンをお探しの方は【${this.params.year}年開催の${placeName}の趣味コン】一覧ページからお探しください。`
    }
    if (this.pageDetector.isDatesPageWithYearAreaGroupInterests) {
      return `【${this.params.year}年開催の${placeName}（${this.prefecture.name}）の趣味コン掲載中${this.pageText}】】趣味活・趣味コンとは、共通の趣味を持った人たちが集まる出会いのイベントです。共通の趣味だから会話も弾むし、繋がりを持てるのが趣味コンの特徴です。街コンジャパンでは、アニコン（アニメ）、映画、ゲーム、スポーツ、アウトドア、料理など、様々な出会いのイベントをお探しいただけます。${this.params.year}年開催の${placeName}の趣味コンをお探しの方は【${this.params.year}年開催の${placeName}の趣味コン】一覧ページからお探しください。`
    }
    if (this.pageDetector.isDatesPageWithYearAreaInterests) {
      return `【${this.params.year}年開催の${placeName}（${this.prefecture.name}）の趣味コン掲載中${this.pageText}】】趣味活・趣味コンとは、共通の趣味を持った人たちが集まる出会いのイベントです。共通の趣味だから会話も弾むし、繋がりを持てるのが趣味コンの特徴です。街コンジャパンでは、アニコン（アニメ）、映画、ゲーム、スポーツ、アウトドア、料理など、様々な出会いのイベントをお探しいただけます。${this.params.year}年開催の${placeName}の趣味コンをお探しの方は【${this.params.year}年開催の${placeName}の趣味コン】一覧ページからお探しください。`
    }
    if (this.pageDetector.isRegionsPageWithInterestsAnime) {
      return `人気のアニメコンの検索から申込みまで行える総合ポータルサイト(上場企業のリンクバルが運営)。初対面の相手でも、昔見た懐かしのアニメの話題から、今注目のマンガの話題まで、共通の趣味の話題があるから自然と会話が盛り上がります。`
    }
    if (this.pageDetector.isRegionsPageWithInterestsSportsFutsal) {
      return `人気のフットサルコンの検索から申込みまで行える総合ポータルサイト(上場企業のリンクバルが運営)。フットサル未経験者も安心して楽しめるようなイベントが多数ございます。男女を問わずグループで仲良くなりやすいのが特徴です。`
    }
    if (this.pageDetector.isSearchEventsPage) {
      return `${this.searchPageTitle()}検索結果。大型交流イベントから趣味コンまで豊富にライナップ。行きたいイベントが見つかる。趣味、駅ちか、職業、年代別、近日開催など。自分にピッタリな条件で探せる！`
    }
    if (this.pageDetector.isMarketInsightsTopPage) {
      return `街コン・婚活パーティーなどの出会いのイベントに関する調査や、その他の出会い・結婚などに関連する調査の結果をご紹介します。`
    }
    if (this.pageDetector.isMarketInsightsDetailPage) {
      return this.context.announcement.meta_description
    }
    if (this.pageDetector.isActivityTopPage || this.eventType === 'activity')
      return '体験コンとは、共通の体験（アクティビティ）を通じて出会いをお楽しみいただけるイベントです。共通の体験をすることで、すぐに打ち解けることができるのが特徴です。街コンジャパンでは海や山など様々な場所で行うアクティビティ、スポーツ、ものづくり、料理、職業体験など、幅広いジャンルからイベントをお探しいただけます。体験コンをお探しの方は【体験コン】ページからお探しください。'
    if (this.pageDetector.isOrganizerReviewPage)
      return `${this.context.organizer.name}のイベントに実際に参加されたお客様の口コミをご紹介！参加する前に口コミを参考にイベントを選ぶことができます。`
    if ((!/^\/search/.test(this.route.path) && !this.pageDetector.isTopPage) || this.eventType === 'konkatsu') {
      const dateText = this.dateCondition() !== '' ? `${this.dateCondition()}開催の` : ''
      const titleText = dateText + placeName
      const prefecture = this.params.prefecture ? findPrefectureBySlug(this.params.prefecture) : null
      const titleTextLong = prefecture && (this.params.area || this.params.area_group) ? `${titleText}（${prefecture.name}）` : titleText
      const placePrefix = titleText === '全国' ? '' : `${titleText}の`
      const descriptionPrefixText = this.pageDetector.isSpecificAreaPage ? this.prefixText() : ''

      if (this.pageDetector.isSpecialPrefecturePartyPage) {
        const placeText =
          titleText === '全国' ? `【恋活パーティーを紹介${this.pageText}】` : `【${titleTextLong}の恋活パーティー・パーティーを紹介${this.pageText}】`
        return `${placeText}1店舗でお店の中を自由に移動出来るパーティー形式。好みの異性に積極的にアプローチしたい方や同性の友達づくりなど。様々な出会いのイベントをお探しいただけます。${placePrefix}恋活パーティーをお探しの方は【${placePrefix}恋活パーティー・パーティー】ページからお探しください。`
      }

      if (this.eventType && this.eventType === 'party') {
        const placeText =
          titleText === '全国' ? `【恋活パーティーを紹介${this.pageText}】` : `【${titleTextLong}の恋活パーティーを掲載中${this.pageText}】`
        return `${placeText}1店舗でお店の中を自由に移動出来るパーティー形式。好みの異性に積極的にアプローチしたい方や同性の友達づくりなど。様々な出会いのイベントをお探しいただけます。${placePrefix}恋活パーティーをお探しの方は【${placePrefix}恋活パーティー】ページからお探しください。`
      }

      if (this.eventType && this.eventType === 'petit-con') {
        const placeText = titleText === '全国' ? `【プチ街コンを紹介${this.pageText}】` : `【${titleTextLong}のプチ街コン掲載中${this.pageText}】`
        return `${placeText}1店舗で合コンのように少人数でじっくり出会いを楽しみたい方にピッタリ！全国のプチ街コンの検索から申込みまで行える総合ポータルサイト(上場企業のリンクバルが運営)。`
      }
      if (this.eventType && this.eventType === 'konkatsu') {
        if (!this.pageDetector.isAreaKonkatsuPage && !this.pageDetector.isRegionKonkatsuPage)
          return '【会員数累計200万人以上！】国内最大級の婚活パーティー・お見合いパーティー情報サイトmachicon JAPAN。上場企業運営で安心してあなたに合った婚活イベントを探せます。'
        const placeText = this.prefecture && this.prefecture !== this.place ? `${titleText}（${this.prefecture.name}）` : titleText
        return `${descriptionPrefixText}${placeText}の婚活パーティー・お見合いパーティーならmachicon JAPAN 【会員数累計200万人以上！】だから出会いもたくさん。上場企業のリンクバルが運営。`
      }
      if (this.eventType && this.eventType === 'bar_gourmet') {
        return '「バル・グルメイベント」とは、ご友人や会社の同僚など、様々な方にお楽しみいただける「グルメ・食べ物」推し！のイベント（フェス）特集です。東京（都内）のバル・グルメイベントをお探しの方はバル・グルメイベントページからお探しください。'
      }
      if (this.eventType && this.eventType === 'jibunmigaki') {
        const placeText = titleText === '全国' ? `【自分磨きとは？${this.pageText}】` : `【${titleTextLong}の自分磨き掲載中${this.pageText}】`
        return `${placeText} 趣味や教養、スポーツなど楽しく自分を磨けるカルチャースクールのようなレッスンコンテンツです。 新しい学びや発見いっぱいのワークショップ、勉強会、セミナー、習い事等、「自分磨き」に参加する事で、人生を豊かにするヒントを沢山吸収できます。学び・習い事を通じた趣味友達との出会いや、自分を磨く事でより良い出会いに繋がるキッカケ探しにご参加ください。`
      }
      if (this.pageDetector.isInterestTopPage) {
        const placeText = titleText === '全国' ? `【趣味活・趣味コン特集${this.pageText}】` : `【${titleTextLong}の趣味コン掲載中${this.pageText}】`
        return `${placeText}趣味活・趣味コンとは、共通の趣味を持った人たちが集まる出会いのイベントです。共通の趣味だから会話も弾むし、繋がりを持てるのが趣味コンの特徴です。街コンジャパンでは、アニコン（アニメ）、映画、ゲーム、スポーツ、アウトドア、料理など、様々な出会いのイベントをお探しいただけます。${placePrefix}趣味コンをお探しの方は【${placePrefix}趣味コン】ページからお探しください。`
      }
      if (this.pageDetector.isInterestDetailPage && this.interest && this.interest.slug === 'bus') {
        return '人気のバスツアーと婚活をセットにした婚活バスツアー（お見合いバスツアー）の検索サイトです。通常の婚活イベントと比較して、一緒にいる時間が長いため、ゆっくりと話すことができ、ツアーを通して相手の人となりをしっかり見られるのが特徴です。'
      }
      if (this.apiSearchParams['filter[keyword]']) {
        return `【${this.apiSearchParams['filter[keyword]']}の街コン・婚活パーティー${this.pageText}】検索結果。大型交流イベントから趣味コンまで豊富にライナップ。行きたいイベントが見つかる。趣味、駅ちか、職業、年代別、近日開催など。自分にピッタリな条件で探せる！`
      }
      if (this.pageDetector.isInterestDetailPage && this.interest) {
        return `人気の${this.interest.name}の検索から申込みまで行える総合ポータルサイト(上場企業のリンクバルが運営)。未経験者も安心して楽しめるようなイベントが多数ございます。趣味コン・趣味友をお探しの方は街コンジャパンの【${this.interest.name}${this.pageText}】一覧ページからお探しください。`
      }

      if ((this.pageDetector.isAreasInterestsPage || this.pageDetector.isRegionsInterestsPage) && this.interest) {
        return `${titleText}の${this.interest.name}の検索から申込みまで行える総合ポータルサイト(上場企業のリンクバルが運営)。未経験者も安心して楽しめるようなイベントが多数ございます。趣味コン・趣味友をお探しの方は街コンジャパンの【${titleText}の${this.interest.name}${this.pageText}】一覧ページからお探しください。`
      }

      if (this.pageDetector.isFixedEventsPage)
        return `${titleText}で開催が確定したイベントをご紹介★ ※悪天候や自然災害等、不可抗力な事由によって、開催が中止となる場合もございます。`

      return `${descriptionPrefixText}${titleTextLong}の街コン・婚活パーティーで出会いを探すなら【掲載数／会員数No.1】の『街コンジャパン』。${this.totalCount}件の中から条件にあったイベントをお探しいただけます。イベント自体も楽しみたい／効率良く出会いたいなど恋活・婚活のスタイルや、趣味を通じて出会いたいなどイベント内容まで、様々な要望にお応えできる豊富なイベントをご用意しています。`
    }

    return ''
  }

  noDescriptionPages() {
    if (this.pageDetector.isPurchasesTopPage) return true
    if (this.pageDetector.isSimilarEventsPage) return true
    if (this.pageDetector.isEventBookmarksPage) return true
    // If search param is not an area name, we do not want to proceed
    if (this.apiSearchParams['filter[keyword]'] && !isPlaceName(this.apiSearchParams['filter[keyword]'])) return true

    return false
  }

  noIndexPromoterPage() {
    return this.pageDetector.isStaticPromoterPage
  }

  isNotFoundPage() {
    return this.context.$store.$state.error_code === 404
  }

  keywordText() {
    const keywords = []
    const currentEvent = this.context.event
    const eventTypeText = currentEvent && currentEvent.type ? EventModel.getEventTypeName(currentEvent.type) : '街コン'

    if (this.isMensPassOrKonkatsuFreePassEvent()) return '街コン'
    if (this.eventType) {
      if (this.eventType === 'konkatsu') keywords.push('婚活パーティー', 'お見合いパーティー')
      else keywords.push(EventModel.getEventTypeName(this.eventType))
    }

    if (this.pageDetector.isEventDetailPage) {
      keywords.push('全国')

      if (currentEvent.region_name) keywords.push(`${currentEvent.region_name}地方`)
      if (currentEvent.prefecture_name) keywords.push(currentEvent.prefecture_name)
      if (currentEvent.area_group_name) keywords.push(currentEvent.area_group_name)
      if (currentEvent.area_name) keywords.push(currentEvent.area_name)
      if (currentEvent.self_type_tag_buttons)
        currentEvent.self_type_tag_buttons.forEach(tag => {
          keywords.push(tag.name)
        })
      if (currentEvent.feature_tags)
        currentEvent.feature_tags.forEach(tag => {
          keywords.push(tag.name)
        })

      keywords.push(this.context.meta && this.context.meta.keywords ? this.context.meta.keywords : currentEvent.title)
    } else if (this.pageDetector.isStaticPage) {
      if (this.context.metaKeywords) keywords.push(this.context.metaKeywords)
      else {
        keywords.push('街コン,街こん,メガ合コン,まちコン,まちこん,マチコン,合コン,コンパ,イベント,パーティー,交流,大規模,地域活性化,東京,名古屋,大阪')
        keywords.push(this.context.title)
      }
    } else if (this.pageDetector.isRegionRankingPage && this.region) {
      keywords.push(`街コンジャパン主催（${this.region.name}）街コン`)
      keywords.push('街コン,街こん,メガ合コン,まちコン,まちこん,マチコン,イベント,パーティー,交流,大規模')
      keywords.push(`${this.region.name}の街コン`)
      keywords.push(`街コンランキング（${this.region.name}）`)
    } else if (this.pageDetector.isPrefectureRankingPage || this.pageDetector.isAreaGroupRankingPage || this.pageDetector.isAreaRankingPage) {
      if (this.place) {
        keywords.push(`街コンジャパン主催（${this.place.name}）${eventTypeText}`)
        keywords.push('街コン,街こん,メガ合コン,まちコン,まちこん,マチコン,イベント,パーティー,交流,大規模')
        keywords.push(`${this.place.name}の${eventTypeText}`)
        keywords.push(`${eventTypeText}ランキング（${this.place.name}）`)
      }
    } else if (this.customSearch) {
      keywords.push(this.customSearch.meta_keywords ? this.customSearch.meta_keywords : this.customSearch.name)
    } else if (this.featureTag) {
      keywords.push(this.featureTag.meta_keywords ? this.featureTag.meta_keywords : this.featureTag.name)
    } else if (this.promoter) {
      keywords.push(this.promoter.meta_keywords ? this.promoter.meta_keywords : this.organizer)
    } else if (
      !this.pageDetector.isSearchEventsPage &&
      (this.eventType ||
        this.region ||
        this.prefecture ||
        this.area ||
        this.dateCondition() ||
        this.pageDetector.isInterestEventsPage ||
        this.interest)
    ) {
      if (!this.pageDetector.isKonkatsuEventsPages) keywords.push('全国')
      if (this.region) keywords.push(`${this.region.name}地方`)
      else if (!this.region && this.prefecture) {
        const regionModel = findRegionByPrefectureSlug(this.params.prefecture)
        if (regionModel) keywords.push(`${regionModel.name}地方`)
      }
      if (this.prefecture) keywords.push(this.prefecture.name)
      if (this.areaGroup) keywords.push(this.areaGroup.name)
      if (this.area) keywords.push(this.area.name)
      if (this.dateCondition()) keywords.push(`${this.dateCondition()}の${eventTypeText}`)
      if (this.pageDetector.isInterestEventsPage) keywords.push('趣味コン')
      if (this.interest) keywords.push(this.interest.name)
    }

    const keywordsText = keywords.length > 0 ? keywords.join(',') : ''
    return keywordsText.toLowerCase()
  }

  isMensPassOrKonkatsuFreePassEvent() {
    return (
      this.pageDetector.isEventDetailPage &&
      (this.route.params.code === import.meta.env.KONKATSU_FREEPASS_EVENT_CODE || this.route.params.code === import.meta.env.MENSPASS_EVENT_CODE)
    )
  }

  featureTagTitle() {
    const titleByConditions = []
    if (this.route.name.includes('dates')) titleByConditions.push(`${eventOrganizedDate(this.params)}の`)
    if (this.place) titleByConditions.push(this.titleAreaCondition())
    titleByConditions.push(this.featureTag.name)
    return `${titleByConditions.join('')}一覧`
  }

  featureTagDescription(featureTag, searchPageTitle) {
    const searchTitle = '社会人サークル・オフ会'
    const slug = 'tomokatsu'
    const featureName = featureTag.name || ''
    if (!featureTag.meta_description) {
      return `${searchPageTitle}当日でも間に合う！【${featureName}】をご紹介☆ 休日は予定があるし、会社帰りなら参加出来るのに… 当日でも間に合う！仕事帰りにふらっと参加出来るイベントを集めました！ 1人参加の方沢山います★アフター5を充実させませんか？`
    }
    if (searchPageTitle.includes(searchTitle) && featureTag.meta_description.includes(searchTitle) && featureTag.slug === slug) {
      searchPageTitle = searchPageTitle.replace('友達作り・友達探し（社会人サークル・オフ会）', '')
    }
    return `${searchPageTitle}${featureTag.meta_description}`
  }
}
