export const PAGING_TYPE_PATH = 'path' // paging by path: search/page/3
export const PAGING_TYPE_QUERY = 'query' // paging by query: search?page=3
export const DEFAULT_LIMIT_PER_PAGE = 24

const getRouteNameByPage = (currentRouteName, page) => {
  if (page === 1 && /-page$/.test(currentRouteName)) return currentRouteName.replace(/-page$/, '')
  if (page > 1 && !/-page$/.test(currentRouteName)) return `${currentRouteName}-page`
  return currentRouteName
}

export const getPageLink = (currentRoute, page, pagingType = PAGING_TYPE_PATH) => {
  const currParams = { ...currentRoute.params }
  const currQuery = { ...currentRoute.query }
  if (pagingType === PAGING_TYPE_PATH) {
    currParams.page = page === 1 ? null : page
  } else if (pagingType === PAGING_TYPE_QUERY) {
    if (page === 1) {
      delete currQuery.page
    } else {
      currQuery.page = page
    }
  }
  const routeName = pagingType === PAGING_TYPE_PATH ? getRouteNameByPage(currentRoute.name, page) : currentRoute.name
  return { name: routeName, params: currParams, query: currQuery }
}

export const pageEntriesText = (currentPage, totalCount, perPage) => {
  const hasManyPage = totalCount / perPage > 1
  if (currentPage === 1 && !hasManyPage) return `${totalCount}件中1 - ${totalCount}件表示しています。`
  if (hasManyPage) {
    const beginPage = perPage * (currentPage - 1) + 1
    const endPage = perPage * currentPage > totalCount ? totalCount : perPage * currentPage
    return `${totalCount}件中${beginPage} - ${endPage}件表示しています。`
  }
  return ''
}

export const getPaginationInfo = (route, totalCount = 0) => {
  const currentPage = Number(route.params.page) || 1
  const totalPages = Math.ceil(totalCount / DEFAULT_LIMIT_PER_PAGE)
  return { currentPage, totalPages }
}
