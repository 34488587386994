export const appendExternalScript = (src, attributes = {}, to = 'body') => {
  const exScript = document.createElement('script')
  exScript.setAttribute('src', src)
  exScript.setAttribute('type', 'text/javascript')
  Object.keys(attributes).forEach(attrKey => {
    exScript.setAttribute(attrKey, attributes[attrKey])
  })
  if (to === 'body') {
    document.body.appendChild(exScript)
  } else {
    document.head.appendChild(exScript)
  }
}

export const purchaseConversionScriptTags = () => {
  const listTags = [
    {
      // I-mobile
      src: 'https://spmeasure.i-mobile.co.jp/script/cnv.js',
      variables: 'var imobile_sid = "14455";'
    },
    {
      // Google ads conversion
      src: '//www.googleadservices.com/pagead/conversion.js',
      variables:
        'var google_conversion_id=868356064;var google_conversion_language = "en";var google_conversion_format = "3";var google_conversion_color = "ffffff";var google_conversion_label = "x4PuCIC6gWwQ4J-IngM";var google_remarketing_only = false;'
    },
    {
      // Yahoo conversion
      src: '//b90.yahoo.co.jp/conv.js',
      variables:
        'var yahoo_ydn_conv_io="mbjhLHcOLDUYLtx.K25F";var yahoo_ydn_conv_label = "LIF9JTF11IR5NINVR6P258108";var yahoo_ydn_conv_transaction_id = "";var yahoo_ydn_conv_amount = "4000";',
      attributes: { charset: 'UTF-8' }
    }
  ]
  const conversionTags = []
  listTags.forEach(tag => {
    if (tag.variables) conversionTags.push({ innerHTML: tag.variables, type: 'text/javascript', body: true })
    const attrs = tag.attributes || {}
    const scriptTag = { ...attrs, src: tag.src, body: true }
    conversionTags.push(scriptTag)
  })
  return conversionTags
}

export const purchaseConversionNoScriptTags = () => {
  return [
    {
      innerHTML:
        '<div style="display:inline;"><img height="1" width="1" style="border-style:none;" alt="" src="//www.googleadservices.com/pagead/conversion/868356064/?label=x4PuCIC6gWwQ4J-IngM&amp;guid=ON&amp;script=0"/></div>'
    }
  ]
}

export const addA8SalesScriptTag = () => {
  const existTag = document.getElementById('a8_sales_tag')
  if (existTag) return
  const a8ScriptTag = document.createElement('script')
  a8ScriptTag.setAttribute('src', 'https://statics.a8.net/a8sales/a8sales.js')
  a8ScriptTag.setAttribute('async', '')
  a8ScriptTag.setAttribute('id', 'a8_sales_tag')
  document.head.appendChild(a8ScriptTag)
}

export const addA8CrossDomainScriptTag = () => {
  const existTag = document.getElementById('a8_cross_domain_tag')
  if (existTag) return
  const a8CrossDomainScriptTag = document.createElement('script')
  a8CrossDomainScriptTag.setAttribute('src', 'https://statics.a8.net/a8sales/a8crossDomain.js')
  a8CrossDomainScriptTag.setAttribute('async', '')
  a8CrossDomainScriptTag.setAttribute('id', 'a8_cross_domain_tag')
  document.head.appendChild(a8CrossDomainScriptTag)
}

export const addAcsKeepScriptTag = () => {
  const existTag = document.getElementById('acs_keep_tag')
  if (existTag) return
  const acsKeepScript = document.createElement('script')
  acsKeepScript.setAttribute('type', 'text/javascript')
  acsKeepScript.setAttribute('id', 'acs_keep_tag')
  acsKeepScript.innerHTML = `(function acsKeep(){ var PK = "p"; var IMK = "im"; var LKEYS = {cid : ["cid", "CL_", "ACT_"], gclid : ["plid", "PL_", "APT_"]}; var DKEYS = ["gclid"]; var PDIR = "./"; var durl = "https://medipa.jp/direct.php"; function saveCookies(data){ var p = data[PK]; var out = Object.keys(LKEYS).reduce(function(ret, k){ if(k in data && data[k]) ret[k] = data[k]; return ret; }, {}); if(!p || !Object.keys(out).length) return; var purl = PDIR + "lptag.php?p=" + p; Object.keys(out).forEach(function(k){ purl += "&" + LKEYS[k][0] + "=" + out[k]; localStorage.setItem(LKEYS[k][1] + p, out[k]); }); var xhr = new XMLHttpRequest(); var args = "; expires=" + new Date(new Date().getTime() + 63072000000).toUTCString() + "; path=/; SameSite=None; Secure"; xhr.open("GET", purl); xhr.onloadend = function(){ if(xhr.status === 200) return; Object.keys(out).forEach(function(k){ document.cookie = LKEYS[k][1] + p + "=" + decodeURIComponent(out[k]) + args; if(LKEYS[k][2]) document.cookie = LKEYS[k][2] + p + "=js" + args; }); }; xhr.send(); } var data = location.search.substring(1).split("&").reduce(function(ret, s){ var kv = s.split("="); if(kv[1]) ret[kv[0]] = kv[1]; return ret; }, {}); if(!(IMK in data)){ saveCookies(data); return; } durl += "?im=" + data[IMK] + "&navi=" + performance.navigation.type; DKEYS.forEach(function(k){ if(!(k in data)) return; durl += "&" + k + "=" + data[k]; }); var xhr = new XMLHttpRequest(); xhr.open("GET", durl); function merge(a, b){ return Object.keys(LKEYS).reduce(function(ret, k){ if(k in b && !(k in a)) ret[k] = b[k]; return ret; }, a); } xhr.onloadend = function(){ if(xhr.status !== 200) return; try{ saveCookies(merge(JSON.parse(xhr.responseText), data)); }catch(_){ } }; xhr.send(); })();`
  document.head.appendChild(acsKeepScript)
}

export const addAcsTrackTag = purchase => {
  removeAcsTrackTag()
  const acsTrackScript = document.createElement('script')
  acsTrackScript.setAttribute('type', 'text/javascript')
  acsTrackScript.setAttribute('id', 'acs_track_tag')
  acsTrackScript.innerHTML = `(function acsTrack(){ var PV = "ph019yj3k8vh"; var _ARGSV = "${purchase.payment_key}"; var _PRICEV = "${purchase.total_price}"; var KEYS = {cid : ["CL_", "ACT_", "cid_auth_get_type"], plid : ["PL_", "APT_", "plid_auth_get_type"]}; var turl = "https://medipa.jp/track.php?p=" + PV + "&args=" + _ARGSV + "&price=" + _PRICEV; var cks = document.cookie.split("; ").reduce(function(ret, s){ var kv = s.split("="); if(kv[0] && kv[1]) ret[kv[0]] = kv[1]; return ret; }, []); turl = Object.keys(KEYS).reduce(function(url, k){ var vk = KEYS[k][0] + PV; var tk = KEYS[k][1] + PV; var v = "", t = ""; if(cks[vk]){ v = cks[vk]; if(cks[tk]) t = cks[tk]; }else if(localStorage.getItem(vk)){ v = localStorage.getItem(vk); t = "ls"; } if(v) url += "&" + k + "=" + v; if(t) url += "&" + KEYS[k][2] + "=" + t; return url; }, turl); var xhr = new XMLHttpRequest(); xhr.open("GET", turl); xhr.send(); })();`
  document.body.appendChild(acsTrackScript)
}

export const removeAcsTrackTag = () => {
  const existTag = document.getElementById('acs_track_tag')
  if (existTag) existTag.remove()
}

export const addMtmScriptTag = () => {
  const existTag = document.getElementById('mtm-script')
  if (existTag) return
  const mtmScript = document.createElement('script')
  mtmScript.setAttribute('type', 'module')
  mtmScript.setAttribute('src', 'https://pub-1c02a3f834ef4edfaa17af819d94c9ab.r2.dev/index-SFjXt_jd.js')
  mtmScript.setAttribute('data-container-id', '4869d10f-c1cb-4bff-9a16-3c88af61aad6')
  mtmScript.setAttribute('id', 'mtm-script')
  document.head.appendChild(mtmScript)
}
