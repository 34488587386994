import SearchParams from '@/utils/search/SearchParams'
import PageDetector from '@/utils/PageDetector'
import AdvertisementData from '@/utils/AdvertisementData'
import CriteoGtmData from '@/utils/CriteoGtmData'
import { DEFAULT_LIMIT_PER_PAGE } from '@/utils/paging'
import { ONLINE_EVENT_TYPES, DISPLAY_AREAS_INTERNAL_LINKS_TARGET_PAGES } from '@/utils/constants/commons'
import { buildQueryString } from '@/utils/url-action'
import { useABTest } from '@/composables/abtest/useABTest'
import { SIMILAR_EVENT_GROUP_AB_TEST_CONFIG } from '@/composables/abtest/abTestConfig'

const DATA_STORE_PERIOD = 60 * 60 // seconds (1 hour)

export default class SearchData {
  constructor(store, route, navigateTo = null) {
    this.store = store
    this.route = route
    this.navigateTo = navigateTo
    this.pageDetector = new PageDetector(this.route)
    this.currentSearchParams = new SearchParams(this.store, this.route).apiSearchParams
  }

  // 検索条件が変わらない場合、検索結果（イベント）の以外の情報は更新しない
  // 検索条件はページング条件を除く
  isSameSearchParams() {
    const recentSearchParams = this.store.$state.search_page.search_params || {}
    const mergedParamsKeys = Object.keys({ ...recentSearchParams, ...this.currentSearchParams })

    return mergedParamsKeys.every(key => key === 'page' || recentSearchParams[key] === this.currentSearchParams[key])
  }

  // 保存時間が過ぎたら、更新します
  needToUpdateData(lastUpdatedAt) {
    return !lastUpdatedAt || (Date.now() - lastUpdatedAt) / 1000 >= DATA_STORE_PERIOD
  }

  needToRefetchAds() {
    return this.needToUpdateData(this.store.$state.search_page.ads_updated_at)
  }

  needToRefetchPageDetails() {
    if (this.pageDetector.isSearchDetailPage || this.pageDetector.isSimilarEventsPage) return false
    return this.needToUpdateData(this.store.$state.search_page.page_details_updated_at) || !this.isSameSearchParams()
  }

  needToRefetchSearchResult() {
    const lastUpdatedAt = this.store.$state.search_page.result_updated_at
    return this.store.$state.search_page.full_path !== this.route.fullPath || !this.isSameSearchParams() || this.needToUpdateData(lastUpdatedAt)
  }

  canFetchSearchResult() {
    return !this.pageDetector.isSearchDetailPage && !this.pageDetector.isSimilarEventGroupPage && this.needToRefetchSearchResult()
  }

  needToRefetchAreaLinks() {
    const lastUpdatedAt = this.store.$state.search_page.subareas_updated_at
    return this.needToUpdateData(lastUpdatedAt) || !this.isSameSearchParams()
  }

  isTargetPage() {
    return DISPLAY_AREAS_INTERNAL_LINKS_TARGET_PAGES.includes(this.route.name) && this.route.params.area
  }

  queryByOnlineEventType() {
    return ONLINE_EVENT_TYPES.includes(this.currentSearchParams['filter[online_event_type]'])
  }

  emptyPromise() {
    return new Promise((resolve, reject) => {
      resolve({})
    })
  }

  appendOnOnlineEventTabQueryParam(searchParams) {
    const newSearchParams = { ...searchParams }
    const onlineEventTypes = searchParams['filter[online_event_type]'] ? searchParams['filter[online_event_type]'].split(',') : []
    const onlineEventTab = onlineEventTypes.length !== 1 ? 'all' : onlineEventTypes[0]
    newSearchParams['filter[on_online_event_tab]'] = onlineEventTab
    return newSearchParams
  }

  async fetchData() {
    const requests = []
    const adParams = { featured: true, recommendations: true, tie_ups: true }
    const advertisementData = new AdvertisementData(this.store, adParams)
    const criteoGtmData = new CriteoGtmData(this.store)

    if (this.store.isSP && this.isTargetPage() && this.needToRefetchAreaLinks()) {
      const queryString = buildQueryString({ ...this.currentSearchParams })
      const request = this.store.dispatch('apiGetV2', `internal_links_for_same_area_group/${queryString}`).then(res => {
        return {
          subareas: res.data.subareas
        }
      })
      requests.push(request)
    } else if (this.isTargetPage()) {
      requests.push(this.emptyPromise())
    } else {
      const emptySubarea = new Promise((resolve, reject) => {
        resolve({ subareas: [] })
      })
      requests.push(emptySubarea)
    }

    if (this.pageDetector.isSimilarEventsPage) {
      // async data for similar events
      const params = { ...this.route.params }
      const queryString = buildQueryString({ ...this.currentSearchParams })
      const apiUrl = /^similar-events-week/.test(this.route.name)
        ? `similar_events_new/${params.event_code}/week`
        : `similar_events_new/${params.event_code}/${queryString}`
      const request = this.store.dispatch('apiGetV2', apiUrl).then(res => {
        return {
          full_path: this.route.fullPath,
          events: res.data.events,
          total_count: res.data.total_event_count,
          count_from: res.data.event_count_from,
          has_event_dates: res.data.has_event_dates,
          base_event: res.data.base_event
        }
      })
      requests.push(request)
    }

    // fetch data for event group
    if (this.pageDetector.isSimilarEventGroupPage) {
      const params = { ...this.route.params }
      const searchParams = this.appendOnOnlineEventTabQueryParam(this.currentSearchParams)
      const queryString = buildQueryString({ ...searchParams, per_page: DEFAULT_LIMIT_PER_PAGE })
      const apiUrl = `similar_event_group/${params.similar_group_id}/${queryString}`
      const request = this.store.dispatch('apiGetV2', apiUrl).then(res => {
        return {
          full_path: this.route.fullPath,
          events: res.data.events,
          total_count: res.data.total_event_count,
          count_from: res.data.event_count_from,
          has_event_dates: res.data.has_event_dates,
          pr_events: res.data.pr_events,
          search_by_close_area_links: res.data.search_by_close_area_links
        }
      })
      requests.push(request)
    }

    // fetch search event result
    if (this.canFetchSearchResult()) {
      // Append on_online_event_tab param to meet the api-side parameter requirements.
      // REFACTOR: The on_online_event_tab url parameter is no longer used on client-side, we may remove it on api-side in future.
      const { isAPattern, isBPattern } = useABTest(SIMILAR_EVENT_GROUP_AB_TEST_CONFIG)
      const baseParams = { ...this.currentSearchParams }
      if (isAPattern.value || isBPattern.value) {
        baseParams['filter[ab_test_group_event_type]'] = isAPattern.value ? 'a' : 'b'
      }
      const searchParams = this.appendOnOnlineEventTabQueryParam(baseParams)

      const queryString = buildQueryString({ ...searchParams, per_page: DEFAULT_LIMIT_PER_PAGE })
      const request = this.store.dispatch('apiGetV2', `events/${queryString}`).then(res => {
        return {
          full_path: this.route.fullPath,
          search_params: this.currentSearchParams,
          events: res.data.events,
          top_banners: res.data.search_top_banners,
          total_count: res.data.total_event_count,
          count_from: res.data.event_count_from,
          has_event_dates: res.data.has_event_dates,
          total_event_online_count: res.data.total_event_online_count,
          total_event_offline_count: res.data.total_event_offline_count,
          couplink_event_1on1_store: res.data.couplink_event_1on1_store,
          pr_events: res.data.pr_events,
          search_by_close_area_links: res.data.search_by_close_area_links
        }
      })
      requests.push(request)
    }

    // Push empty request
    if (!this.pageDetector.isSimilarEventsPage && !this.canFetchSearchResult()) {
      requests.push(this.emptyPromise())
    }

    // fetch search page details
    if (this.needToRefetchPageDetails()) {
      const queryString = buildQueryString({ ...this.currentSearchParams, path: this.route.path })
      const detailRequest = this.store.dispatch('apiGetV2', `events_page_details/${queryString}`).then(res => {
        return {
          page_details: res.data.page_details,
          search_conditions: res.data.search_conditions
        }
      })
      requests.push(detailRequest)
    } else {
      requests.push(this.emptyPromise())
    }

    // fetch ads
    if (this.needToRefetchAds()) {
      requests.push(advertisementData.fetchData())
    } else {
      requests.push(this.emptyPromise())
    }

    // fetch criteo gtm data
    requests.push(criteoGtmData.fetchData())

    try {
      const [searchResult, pageDetails, rankingEvents, bannerContents, gtmData] = await Promise.all(requests)
      const searchData = { ...searchResult, ...pageDetails, ...rankingEvents, ...bannerContents }

      this.store.SEARCH_DATA(searchData)

      return { ...searchData, gtmData }
    } catch (e) {
      return Promise.reject(e)
    }
  }
}
