import canSetNoIndexForSearchEventPage from '@/utils/checkNoIndexSearchPages'
import PageDetector from '@/utils/PageDetector'
import { WHITELIST_NOINDEX_SEARCH_KEYWORD_URLS } from '@/utils/constants/whitelistNoIndexSearchKeywordUrls'
import { NOINDEX_PAGE_NAME, NOINDEX_PATH } from '@/utils/constants/noIndexPage'
import { WHITELIST_NOINDEX_SEARCH_URL_PARAMS, WHITELIST_SLUG } from '@/utils/constants/whitelistNoIndexSearch'
import { getPaginationInfo } from '@/utils/paging'
import moment from 'mj-moment'
import SearchRoute from '@/router/searchRoutes'
import type { ApiSearchParams, DynamicUrlParams, MetaContext } from '@/types'
import type { RouteLocationNormalizedLoaded } from 'vue-router'

// Issue 5867 if change list CATEGORIES please sync with this EVENT_TYPE_SEO_TARGET list on the api side URL: app/models/event.rb
const CATEGORIES = ['party', 'konkatsu', 'jibunmigaki', 'bar_gourmet', 'other']

const MAXIMUM_NUMBER_OF_SEARCH_URL_PARAMS = 5

interface UrlDateParams {
  year?: string
  month?: string
  day?: string
}

type BuildDateByUrlParams = (urlDateParams: UrlDateParams) => string | null

const buildDateByUrlParams: BuildDateByUrlParams = urlDateParams => {
  if (!urlDateParams.year && !urlDateParams.month && !urlDateParams.day) return null

  const date = moment()
  const year = urlDateParams.year ? parseInt(urlDateParams.year) : null
  const month = urlDateParams.month ? parseInt(urlDateParams.month) - 1 : null
  const day = urlDateParams.day ? parseInt(urlDateParams.day) : null

  if (day && year && month) return date.year(year).month(month).date(day).format()
  if (month !== null && year) return date.year(year).month(month).endOf('month').startOf('date').format()
  if (year) return date.year(year).endOf('year').startOf('date').format()
  return null
}

type HasInvalidDateParam = (urlDateParams: UrlDateParams) => boolean

const hasPastDateParam: HasInvalidDateParam = urlDateParams => {
  const date = buildDateByUrlParams(urlDateParams)
  if (date === null) return false
  return moment().diff(date, 'days') > 0
}

const hasDateAfterFourMonthsParam: HasInvalidDateParam = urlDateParams => {
  const date = buildDateByUrlParams(urlDateParams)
  if (date === null) return false
  return moment().add(4, 'months').isBefore(date)
}

type CheckPageWithSlug = (route: RouteLocationNormalizedLoaded) => boolean

const isNoIndexPageSlug: CheckPageWithSlug = route => {
  // whitelist slug かつ、クエリパラメータが存在する場合
  return WHITELIST_SLUG.some(slug => route.path.includes(slug)) && !!Object.keys(route.query).length
}

type CheckSearchPageWithUrlParams = (pageDetector: PageDetector, route: RouteLocationNormalizedLoaded) => boolean

const isSearchEventRouteHasUrlParams: CheckSearchPageWithUrlParams = (pageDetector, route) => {
  // search ページですが、/search/ ではない、かつ、クエリパラメータが存在する場合
  return SearchRoute.ListSearchEventsRoutesSet.includes(route.name) && !pageDetector.isSearchPage && !!Object.keys(route.query).length
}

const isSearchPageExceedingURLParams: CheckSearchPageWithUrlParams = (pageDetector, route) => {
  // /search/ のページで、クエリパラメータが5以上の場合
  if (!pageDetector.isSearchPage) return false

  const routeKey = Object.keys(route.query)
  const urlParamsCount = routeKey.reduce((acc, key) => (route.query[key] instanceof Array ? acc + route.query[key]!.length : acc + 1), 0)
  return urlParamsCount > MAXIMUM_NUMBER_OF_SEARCH_URL_PARAMS
}

type IsNoIndexPageURL = (route: RouteLocationNormalizedLoaded) => boolean

const isNoIndexPageURL: IsNoIndexPageURL = route => {
  const isNoindexPageByUrlParams = !Object.keys(route.query).every(q => WHITELIST_NOINDEX_SEARCH_URL_PARAMS.includes(q as DynamicUrlParams))
  const routeName = route.name?.toString() ?? ''
  return NOINDEX_PAGE_NAME.includes(routeName) || NOINDEX_PATH.includes(route.path) || isNoindexPageByUrlParams
}

type IsNoIndexPages = (context: MetaContext, apiSearchParams: ApiSearchParams) => boolean

const isNoIndexPages: IsNoIndexPages = (context, apiSearchParams) => {
  const route = context.$route
  const currentEvent = context.event
  const totalCount = context.totalCount ? parseInt(context.totalCount) : 0
  const pageDetector = new PageDetector(route)
  const params = { ...route.query, ...route.params }
  const hasCategories =
    typeof route.params.category === 'string'
      ? CATEGORIES.includes(route.params.category)
      : route.params.category?.some((category: string) => CATEGORIES.includes(category))
  const { currentPage, totalPages } = getPaginationInfo(route, totalCount)
  if (pageDetector.isCategoryPage && !hasCategories) return true
  if (pageDetector.isDateEventsPage && (hasPastDateParam(params) || (hasDateAfterFourMonthsParam(params) && totalCount === 0))) return true
  if (pageDetector.isPurchasesTopPage) return true
  if (pageDetector.isSearchEventsPage && totalCount === 0) return true
  if (pageDetector.isEventDetailPage && currentEvent && currentEvent.priority < 0) return true
  if (pageDetector.isEventBookmarksPage || pageDetector.isEventBookmarksGuestPage) return true
  if (pageDetector.isSearchEventsPage && canSetNoIndexForSearchEventPage({ ...apiSearchParams })) return true
  if (pageDetector.isAreaOtherPage) return true
  if (pageDetector.isAreasPage && totalCount === 0) return true
  if (pageDetector.isAreasPage && currentPage > 1) return true
  if (currentPage > totalPages && totalPages > 0) return true
  if (isNoIndexPageSlug(route)) return true
  if (isSearchEventRouteHasUrlParams(pageDetector, route)) return true
  if (isSearchPageExceedingURLParams(pageDetector, route)) return true
  if (route.query.s && !WHITELIST_NOINDEX_SEARCH_KEYWORD_URLS.includes(decodeURIComponent(route.fullPath))) return true
  return isNoIndexPageURL(route)
}

export default isNoIndexPages
