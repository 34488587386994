import { PAGING_TYPE_PATH, PAGING_TYPE_QUERY, getPageLink } from '@/utils/paging'
import { buildQueryString } from '@/utils/url-action'
import isNoIndexPages from '@/utils/CheckNoIndexPages'
import { GOOGLE_ANALYTICS_PARAMS, SUPPORT_LANGUAGES, DEFAULT_LANGUAGE } from '@/utils/constants/commons'
import { WHITELIST_DYNAMIC_URL_PARAMS_PRIORITY } from '@/utils/constants/whitelistNoIndexSearch'
import PageDetector from '@/utils/PageDetector'

const DEFAULT_LIMIT_PER_PAGE = 20
const ROOT_URL = import.meta.env.ROOT_URL

const COMMON_PRECONNECT_LINKS = [
  'https://trj.valuecommerce.com',
  'https://googleads.g.doubleclick.net',
  'https://www.google-analytics.com',
  'https://connect.facebook.net',
  'https://a.imgvc.com',
  'https://storage.googleapis.com',
  'https://static.criteo.net',
  'https://www.google.co.jp',
  'https://www.google.com',
  'https://www.googleadservices.com',
  'https://www.googletagmanager.com',
  'https://t.linkbal.com',
  'https://pub-1c02a3f834ef4edfaa17af819d94c9ab.r2.dev'
]

const PRECONNECT_TOP = [
  'https://asia-northeast1-linkbal-dp.cloudfunctions.net',
  'https://www.facebook.com',
  'https://bid.g.doubleclick.net',
  'https://cf.im-apps.net',
  'https://api.kaiu-marketing.com'
]

const EVENT_DETAIL_PRECONNECT_LINKS = [
  'https://sslwidget.criteo.com',
  'https://gum.criteo.com',
  'https://stats.g.doubleclick.net',
  'https://bid.g.doubleclick.net',
  'https://b.im-apps.net',
  'https://cm.g.doubleclick.net',
  'https://cf.im-apps.net',
  'https://cdn.smartnews-ads.com'
]

const SEARCH_PRECONNECT_LINKS = [
  'https://sslwidget.criteo.com',
  'https://gum.criteo.com',
  'https://i.smartnews-ads.com',
  'https://sync.im-apps.net',
  'https://analytics.twitter.com',
  'https://match.adsrvr.org',
  'https://www.facebook.com',
  'https://cm.g.doubleclick.net',
  'https://cdn.smartnews-ads.com',
  'https://stats.g.doubleclick.net'
]

export default class HeadLink {
  constructor(context, apiSearchParams) {
    this.context = context
    this.route = context.$route
    this.router = context.$router
    this.params = { ...this.route.params, ...this.route.query }
    this.apiSearchParams = apiSearchParams
    this.pageDetector = new PageDetector(this.route)

    this.setSearchConditions()
  }

  setSearchConditions() {
    const eventTypes = this.apiSearchParams['filter[event_type]'] || ''

    // event関連情報の初期値をセット
    this.eventType = eventTypes.includes(',') ? '' : eventTypes
    // イベント一覧ページの判定
    this.isEventListPage = this.context.isEventListPage
    // イベントリストの総数
    this.currentPage = parseInt(this.params.page) || 1
    this.totalCount = parseInt(this.context.totalCount) || 0
  }

  get links() {
    const links = this.intlLink()
    const canonicalFullPath = this.canonicalFullPath()
    const pagingLinks = this.pagingLinks()
    const preconnectLinks = this.preconnectLinks()
    if (!isNoIndexPages(this.context, this.apiSearchParams) && canonicalFullPath) links.push({ rel: 'canonical', href: canonicalFullPath })
    if (preconnectLinks.length > 0)
      preconnectLinks.forEach(link => {
        links.push(link)
      })
    if (pagingLinks.length > 0)
      pagingLinks.forEach(link => {
        links.push(link)
      })
    return links
  }

  canonicalFullPath() {
    // remove google analytics params if it exist
    const queryParams = { ...this.route.query }
    GOOGLE_ANALYTICS_PARAMS.forEach(param => delete queryParams[param])
    const fullPath = ROOT_URL + this.route.path + buildQueryString(queryParams)
    if (this.isNotFoundPage()) return null
    if (this.isIncludeCanonical()) return fullPath

    const formatedPath = fullPath.replace(/(\/\?|\?)(\S|\s)*$/, '/')
    if (this.eventType === 'machicon') return formatedPath.replace(/categories\/machicon\/?$/, '')
    return formatedPath
  }

  isIncludeCanonical() {
    const queryParams = { ...this.route.query }
    const queryKeys = Object.keys(queryParams)
    return queryKeys.some(key => WHITELIST_DYNAMIC_URL_PARAMS_PRIORITY[key])
  }

  pagingLinks() {
    const links = []
    if (!this.isEventListPage || this.totalCount === 0) return links

    const totalPages = Math.ceil(this.totalCount / DEFAULT_LIMIT_PER_PAGE)
    const prevPage = this.currentPage > 1 ? this.currentPage - 1 : null
    const nextPage = this.currentPage < totalPages ? this.currentPage + 1 : null
    const pagingType = this.paggingType()

    if (prevPage) {
      try {
        const resolved = this.router.resolve(getPageLink(this.route, prevPage, pagingType))
        if (resolved && resolved.location && resolved.href) {
          const href = `${ROOT_URL}${resolved.location.path}/${buildQueryString(resolved.location.query)}`
          links.push({ rel: 'prev', href: href })
        }
      } catch {}
    }

    if (nextPage) {
      try {
        const resolved = this.router.resolve(getPageLink(this.route, nextPage, pagingType))
        if (resolved && resolved.location && resolved.href) {
          links.push({ rel: 'next', href: href })
          const href = `${ROOT_URL}${resolved.location.path}/${buildQueryString(resolved.location.query)}`
        }
      } catch {}
    }

    return links
  }

  intlLink() {
    if (!this.pageDetector.isInternationalizationPage) return []
    const locale = SUPPORT_LANGUAGES[this.route.params.lang] || DEFAULT_LANGUAGE
    const fullPathIntl = ROOT_URL + this.route.fullPath
    const fullPath = fullPathIntl.replace(/intl.*/g, '')
    return [
      { rel: 'alternate', hreflang: locale, href: fullPathIntl },
      { rel: 'alternate', hreflang: DEFAULT_LANGUAGE, href: fullPath }
    ]
  }

  preconnectLinks() {
    const urls = []
    if (this.pageDetector.isTopPage) urls.push(...COMMON_PRECONNECT_LINKS, ...PRECONNECT_TOP)
    if (this.isSearchPage) urls.push(...COMMON_PRECONNECT_LINKS, ...SEARCH_PRECONNECT_LINKS)
    if (this.pageDetector.isEventDetailPage) urls.push(...COMMON_PRECONNECT_LINKS, ...EVENT_DETAIL_PRECONNECT_LINKS)

    return urls.map(url => ({ rel: 'preconnect', href: url }))
  }

  paggingType() {
    if (this.apiSearchParams['filter[keyword]']) return PAGING_TYPE_QUERY
    return PAGING_TYPE_PATH
  }

  isNotFoundPage() {
    return this.context.$store.$state.error_code === 404
  }

  isSearchPage() {
    return this.context.$store.$state.is_search_page
  }
}
