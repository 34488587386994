// Append Tracking tags
import { nextTick } from 'vue'
import GTM from '@/utils/tracking/gtm'
import Afb from '@/utils/tracking/afb'
import Affiliate from '@/utils/tracking/affiliate'
import PageDetector from '@/utils/PageDetector'
import { addA8SalesScriptTag, addA8CrossDomainScriptTag, addAcsKeepScriptTag, addMtmScriptTag } from '@/utils/externalScripts'

// event details, purchase success, companion new page, event list, top page need to push extend data. (search detail page doesn't need to push extend data.)
// So in these pages, We will pushData, tracking page view in page component (mounted)
const canPushGtmData = toRoute => {
  const pageDetector = new PageDetector(toRoute)
  if (pageDetector.isEventDetailPage) return false
  if (pageDetector.isPurchaseCompletePage) return false
  if (pageDetector.isCompanionNewPage) return false
  if (pageDetector.isTopPage) return false
  if (pageDetector.isPurchaseNewPage) return false
  if (pageDetector.isGuestPurchasNewPage) return false
  if (pageDetector.isEventListPage) return false
  if (pageDetector.isGreetingPage) return false
  return true
}

const preventTrackingTags = toRoute => {
  const pageDetector = new PageDetector(toRoute)
  return pageDetector.isReceiveTicketPage || pageDetector.isEventPreviewPage
}

export default defineNuxtPlugin(nuxtApp => {
  nuxtApp.$router.afterEach((to, _from) => {
    if (preventTrackingTags(to)) return
    nextTick(() => {
      setTimeout(() => {
        // gtm tag
        if (canPushGtmData(to)) {
          const gtm = new GTM(to, nuxtApp.$store)
          GTM.pushData(gtm.defaultData)
          GTM.trackingPageView(to)
        }

        // afb tag
        Afb.setAccessInfo(to, nuxtApp.$store)

        // affiliate
        Affiliate.setAccessInfo(to, nuxtApp.$store)

        // a8 tag
        addA8SalesScriptTag()
        addA8CrossDomainScriptTag()

        // acs tag
        addAcsKeepScriptTag()

        // mtm tag
        addMtmScriptTag()
      }, 0)
    })
  })
})
