import { findPrefectureBySlug } from '@/models/prefectures'
import PageDetector from '@/utils/PageDetector'
import SearchParams from '@/utils/search/SearchParams'
import moment from 'mj-moment'

export default class GTM {
  constructor(route, store, addData = {}) {
    this.store = store
    this.route = route
    this.pageDetector = new PageDetector(this.route)
    this.addData = addData
  }

  // convert to YYYY-MM-DD
  formatDate(date) {
    return moment(date).format('YYYY-MM-DD')
  }

  get pageTypeId() {
    if (this.route.path === '/' && !this.route.query.s) return 1
    if (this.pageDetector.isAreaPrefecturePage) return 2
    if (this.pageDetector.isEventDetailPage) return 4
    if (this.pageDetector.isEventBookmarksPage) return 5
    if (this.pageDetector.isPurchaseCompletePage) return 6
    return 7
  }

  get currentPrefecture() {
    if (this.addData.prefecture) return this.addData.prefecture

    const apiSearchParams = new SearchParams(this.store, this.route).apiSearchParams
    const prefectureSlug = apiSearchParams['filter[prefecture]']
    if (!prefectureSlug) return null
    // if is selected multiple prefectures
    if (prefectureSlug && prefectureSlug.includes(',')) {
      const firstSlug = prefectureSlug.split(',')[0]
      return findPrefectureBySlug(firstSlug)
    }
    return findPrefectureBySlug(prefectureSlug)
  }

  get userInfo() {
    const prefectureName = () => {
      const slug = this.store.$state.my_profile ? this.store.$state.my_profile.prefecture_slug : null
      if (!slug) return ''
      const prefecture = findPrefectureBySlug(slug)
      return prefecture ? prefecture.name : ''
    }

    const activeKonkatsuFreepass = () => {
      if (this.store.myGender !== 'female') return ''
      return this.store.$state.my_profile && this.store.$state.my_profile.active_konkatsu_freepass ? 'Yes' : 'No'
    }

    const cpmUserInfo = this.store.$state.my_profile && this.store.$state.my_profile.cpm_user_info ? this.store.$state.my_profile.cpm_user_info : null
    return {
      profile_birthdate: this.store.$state.my_profile && this.store.$state.my_profile.birthday ? this.store.$state.my_profile.birthday : '',
      profile_age: this.store.myAge,
      profile_gender: this.store.myGender,
      profile_prefecture: prefectureName(),
      profile_newsletter: this.store.$state.my_profile && this.store.$state.my_profile.newsletter_subscribed ? 'Yes' : 'No',
      customer_id: this.store.$state.my_profile && this.store.$state.my_profile.id ? this.store.$state.my_profile.id : '',
      customer_registration_date:
        this.store.$state.my_profile && this.store.$state.my_profile.created_at ? this.formatDate(this.store.$state.my_profile.created_at) : '',
      active_konkatsu_freepass: activeKonkatsuFreepass(),
      linkbal_id: this.store.$state.my_profile && this.store.$state.my_profile.linkbal_id ? this.store.$state.my_profile.linkbal_id : '',
      first_event_purchased_at:
        this.store.$state.my_profile && this.store.$state.my_profile.first_event_purchased_at
          ? this.store.$state.my_profile.first_event_purchased_at
          : '',
      last_event_purchased_at:
        this.store.$state.my_profile && this.store.$state.my_profile.last_event_purchased_at
          ? this.store.$state.my_profile.last_event_purchased_at
          : '',
      cpm_customer_rank: cpmUserInfo && cpmUserInfo.cpm_customer_rank ? cpmUserInfo.cpm_customer_rank : '',
      cpm_customer_status: cpmUserInfo && cpmUserInfo.cpm_customer_status ? cpmUserInfo.cpm_customer_status : '',
      cpm_last_event_purchased_on: cpmUserInfo && cpmUserInfo.cpm_last_event_purchased_on ? cpmUserInfo.cpm_last_event_purchased_on : '',
      cpm_first_event_purchased_on: cpmUserInfo && cpmUserInfo.cpm_first_event_purchased_on ? cpmUserInfo.cpm_first_event_purchased_on : '',
      cpm_num_of_participations: cpmUserInfo && cpmUserInfo.cpm_num_of_participations ? cpmUserInfo.cpm_num_of_participations : ''
    }
  }

  get deviceType() {
    if (this.store.$state.tablet) return 't'
    if (this.store.$state.sp) return 'm'
    return 'p'
  }

  get defaultData() {
    return {
      ...this.userInfo,
      pagetype_id: this.pageTypeId,
      device_type: this.deviceType,
      view_type: this.store.$state.native_app ? 'a' : 'b',
      prefecture_id: this.currentPrefecture ? this.currentPrefecture.id : '',
      prefecture_name: this.currentPrefecture ? this.currentPrefecture.name : '',
      keyword: this.route.query.s || ''
    }
  }

  static pushData(object = {}) {
    if (typeof window === 'undefined' || !window.dataLayer) return
    if (Object.keys(object).length > 0) window.dataLayer.push(object)
  }

  static sendEcommerceDataToGa(gaEvent, event, userGender, tickets = null) {
    let events = [event]
    if (!event.ticket_offer && tickets) events[0].ticket_offer = tickets
    this.sendEcommerceEventsToGa(gaEvent, events, userGender)
  }

  static sendEcommerceEventsToGa(gaEvent, events, userGender) {
    if (typeof window === 'undefined' || !window.dataLayer) return
    let items = []
    events.forEach(function (event) {
      let item = {
        item_id: event.code,
        item_name: event.title,
        item_brand: event.organizer_name,
        event_date: event.start_at,
        item_category: event.online_event_type,
        item_category3: event.event_type || event.type,
        quantity: 1
      }
      let eventGenderArr = []
      if (!userGender || userGender === 'female') {
        event.ticket_offer
          .filter(ticket => ['female', 'both_gender'].includes(ticket.gender))
          .forEach(function (ticket, index) {
            if (index === 0) eventGenderArr.push('female')
            item['price_female' + (index + 1)] = ticket.price
          })
      }
      if (!userGender || userGender === 'male') {
        event.ticket_offer
          .filter(ticket => ['male', 'both_gender'].includes(ticket.gender))
          .forEach(function (ticket, index) {
            if (index === 0) eventGenderArr.push('male')
            item['price_male' + (index + 1)] = ticket.price
          })
      }
      item.item_category2 = eventGenderArr.join(', ')
      items.push(item)
    })
    window.dataLayer.push({ ecommerce: null }) // Clear the previous ecommerce object (Google recommend).
    window.dataLayer.push({
      event: gaEvent,
      ecommerce: {
        items: items
      }
    })
  }

  static trackingPageView(route) {
    if (typeof window === 'undefined' || !window.dataLayer) return
    window.dataLayer.push({
      event: 'nuxtRoute',
      pageType: 'PageView',
      pageUrl: route.fullPath,
      routeName: route.name
    })
  }

  static sendEventToGa({ category, action, label, value = '', nonInteractive = false } = {}) {
    if (typeof window === 'undefined' || !window.dataLayer) return
    window.dataLayer.push({
      event: 'eventFiredByCode',
      eventCategory: category,
      eventAction: action,
      eventLabel: label,
      eventValue: value,
      nonInteractive: nonInteractive
    })
  }
}
