import { ACTIONS } from '@/utils/constants/gaCustomEventSet'

export const AB_TEST_GA_EVENT_TARGETS = {
  // Top Page Events
  CLICK_SEARCH_BOX: 'click_search_box',
  CLICK_SUGUIKERU: 'suguikeru_click',
  CLICK_BANNER: 'click_banner',
  CLICK_SEARCH_DATE_MODAL: 'click_search_date_modal',
  CLICK_TODAY_TOMORROW: 'click_today_tomorrow',

  // Top Page View Events
  VIEW_TOP: 'view_top_header',

  // Search Modal
  CLICK_SEARCH_MODAL_BUTTON: 'click_search_modal_button',

  // Search Page
  CLICK_SEARCH_EVENT_LIST_ITEM: 'click_search_event_list_item',
  CLICK_SEARCH_SIMILAR_EVENT_GROUP: 'click_search_similar_event_group',
  VIEW_SEARCH: 'view_search_page'

  // Need to add or remove target events when needed
} as const

export type AB_TEST_GA_EVENT_TARGET = (typeof AB_TEST_GA_EVENT_TARGETS)[keyof typeof AB_TEST_GA_EVENT_TARGETS]

export type ABTestGAEvent = {
  target_event: AB_TEST_GA_EVENT_TARGET
  action: string
  Alabel?: string
  Blabel?: string
}

export type ABTestGAEventItem = {
  ab_test_name: string
  category: string
  events: ABTestGAEvent[]
}

export const ABTestGAEventList: ABTestGAEventItem[] = [
  {
    ab_test_name: 'TOP_FV_AB_TEST',
    category: 'TOPFV改修',
    events: [
      {
        target_event: AB_TEST_GA_EVENT_TARGETS.CLICK_SEARCH_BOX,
        action: ACTIONS.CLICK,
        Alabel: 'A_search_box',
        Blabel: 'B_search_box'
      },
      {
        target_event: AB_TEST_GA_EVENT_TARGETS.CLICK_SUGUIKERU,
        action: ACTIONS.CLICK,
        Alabel: 'A_suguikeru',
        Blabel: 'B_suguikeru'
      },
      {
        target_event: AB_TEST_GA_EVENT_TARGETS.CLICK_BANNER,
        action: ACTIONS.CLICK,
        Blabel: 'B_banner'
      },
      {
        target_event: AB_TEST_GA_EVENT_TARGETS.CLICK_SEARCH_DATE_MODAL,
        action: ACTIONS.CLICK,
        Blabel: 'B_date_button'
      },
      {
        target_event: AB_TEST_GA_EVENT_TARGETS.VIEW_TOP,
        action: ACTIONS.VIEW,
        Alabel: 'A_view',
        Blabel: 'B_view'
      },
      {
        target_event: AB_TEST_GA_EVENT_TARGETS.CLICK_TODAY_TOMORROW,
        action: ACTIONS.CLICK,
        Blabel: 'B_event_card'
      },
      {
        target_event: AB_TEST_GA_EVENT_TARGETS.CLICK_SEARCH_MODAL_BUTTON,
        action: ACTIONS.CLICK
      }
      // Add more events here if needed
    ]
  },
  {
    ab_test_name: 'SIMILAR_EVENT_GROUP_AB_TEST',
    category: '同じイベント問題解決abtest',
    events: [
      {
        target_event: AB_TEST_GA_EVENT_TARGETS.CLICK_SEARCH_EVENT_LIST_ITEM,
        action: ACTIONS.CLICK,
        Alabel: 'a_archive_component_202502',
        Blabel: 'b_archive_component_202502'
      },
      {
        target_event: AB_TEST_GA_EVENT_TARGETS.CLICK_SEARCH_SIMILAR_EVENT_GROUP,
        action: ACTIONS.CLICK,
        Blabel: 'archive_anotherdaylink_202502'
      },
      {
        target_event: AB_TEST_GA_EVENT_TARGETS.VIEW_SEARCH,
        action: ACTIONS.VIEW,
        Alabel: 'a_archive_component_view_202502',
        Blabel: 'b_archive_component_view_202502'
      }
    ]
  }
]
