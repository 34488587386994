import { EVENT_LIST_PATHS } from '@/utils/constants/commons'

const STATIC_PROMOTER_PATH = [
  '/craftcocktail/',
  '/konkatsusien/',
  '/promoter_interview/doradoraparty/',
  '/promoter_interview/recruitmarketing/',
  '/promoter_interview/aurora/'
]
const PARTY_PAGE_SPECIAL_PREFECTURES = ['chiba', 'tochigi', 'hyogo', 'kyoto', 'kumamoto']

// detect page by vue route
export default class PageDetector {
  constructor(route) {
    this.route = route
    // handle error when reading includes of undefined
    // this.route.name = this.route.name || ''
  }

  get isTopPage() {
    return this.route.name === 'index' && !this.route.query.s
  }

  get isAreaWithGoogleAdsPage() {
    return this.route.name === 'areas-afs'
  }

  // search by keyword from top page
  // machicon.jp/?s=東京
  get isSearchEventsFromTopPage() {
    return this.route.name === 'index' && this.route.query.s
  }

  get isFixedEventsPage() {
    return this.route.name.indexOf('fixed-events') === 0
  }

  get isFeaturesTopPage() {
    return this.route.name === 'features' || this.route.name === 'features-page'
  }

  get isAreasTopPage() {
    return (this.route.name === 'areas-events' || this.route.name === 'areas-events-page') && this._isNotAllPrefectureAreaGroupArea
  }

  get isAreasDetailPage() {
    return this.route.name.includes('areas') && this.route.params.prefecture
  }

  get isInterestEventsPage() {
    return (
      (this.route.name.includes('interests') && !this.route.path.includes('/interests/activity')) || Number(this.route.query['have-interest']) === 1
    )
  }

  get isInterestsPage() {
    return this.route.name.includes('interests') || Number(this.route.query['have-interest']) === 1
  }

  // The page has path like '/interests/abcxyz'
  get isInterestChildPage() {
    return this.route.name.includes('interests') && this.route.params.interest
  }

  get isInterestTopPage() {
    return (this.route.name === 'interests' || this.route.name === 'interests-page') && !this.route.params.interest
  }

  get isInterestDetailPage() {
    return (this.route.name === 'interests' || this.route.name === 'interests-page') && this.route.params.interest
  }

  get isAreaKonkatsuPage() {
    return this.route.name.includes('areas') && this.route.params.category === 'konkatsu'
  }

  get isPrefectureKonkatsuPage() {
    return this.isAreaKonkatsuPage && !this.route.params.area_group
  }

  get isRegionKonkatsuPage() {
    return this.route.name.includes('regions') && this.route.params.category === 'konkatsu'
  }

  get isAround40Page() {
    return this.route.params.feature_tag === 'around40' || this.route.query.feature === 'around40'
  }

  get isOtakuKonkatsuPage() {
    return this.route.params.feature_tag === 'otakukonkatsu' || this.route.query.feature === 'otakukonkatsu'
  }

  get isYamaconPage() {
    return this.route.params.feature_tag === 'yamacon' || this.route.query.feature === 'yamacon'
  }

  get isFeaturesEventsPage() {
    return this.route.name.includes('features-events')
  }

  get isThisWeekendPage() {
    return this.route.name === 'dates-thisweekend'
  }

  get isNextWeekendPage() {
    return this.route.name === 'dates-nextweekend'
  }

  get isTomokatsuPage() {
    return this.route.params.feature_tag === 'tomokatsu' || this.route.query.feature === 'tomokatsu'
  }

  get isGoconPage() {
    return this.route.params.feature_tag === 'gocon'
  }

  get isActivityTopPage() {
    return this.route.path === '/categories/activity/' || this.route.path === '/interests/activity/'
  }

  get isAreaOrRegionActivityPage() {
    return (this.isSpecificAreaPage || this.isSpecificRegionPage) && this.route.path.includes('/categories/activity')
  }

  get isInterestActivityTopPage() {
    return (this.route.name === 'interests-activity' || this.route.name === 'interests-activity-page') && !this.route.params.activity_theme
  }

  get isInterestActivityDetailPage() {
    return (this.route.name === 'interests-activity' || this.route.name === 'interests-activity-page') && this.route.params.activity_theme
  }

  get isActivityDetailPage() {
    return this.route.params.activity_theme || this.route.params.category === 'activity'
  }

  get isCsp40sokPage() {
    return (
      (this.route.name === 'custom-search-events' || this.route.name === 'custom-search-events-page') && this.route.params.custom_search === '40sok'
    )
  }

  get isSingleCustomSearchPage() {
    return (
      (this.route.name === 'custom-search-events' || this.route.name === 'custom-search-events-page') && this.route.params.custom_search === 'single'
    )
  }

  get isCustomSearchPage() {
    return this.route.name === 'custom-search-events' || this.route.name === 'custom-search-events-page'
  }

  get isAreasCustomSearchPage() {
    return ['areas-custom-search-events', 'areas-custom-search-events-page'].includes(this.route.name)
  }

  get isAreasAreaCustomSearchPage() {
    return ['areas-area-custom-search-events', 'areas-area-custom-search-events-page'].includes(this.route.name)
  }

  get isRegionsCustomSearchPage() {
    return ['regions-custom-search-events', 'regions-custom-search-events-page'].includes(this.route.name)
  }

  get isSimilarEventsPage() {
    return this.route.name.includes('similar-events')
  }

  get isSimilarEventGroupPage() {
    return this.route.name.includes('similar-event-group')
  }

  get isSearchDetailPage() {
    return this.route.name === 'search' && (Object.keys(this.route.query).length === 0 || this.route.query.search_from === 'events')
  }

  // the page has path like search?condition1=abc&condition2=xyz
  // Not the search details page (search form)
  get isSearchEventsPage() {
    return (
      (this.route.name === 'search' || this.route.name === 'search-page') && Object.keys(this.route.query).length > 0 && !this.route.query.search_from
    )
  }

  get isSearchPage() {
    return this.route.name === 'search' || this.route.name === 'search-page'
  }

  get isKonkatsuEventsPages() {
    return this.route.path === '/categories/konkatsu/'
  }

  get isPartnersPages() {
    return this.route.path === '/partners/'
  }

  get areaTokyoKonkatsuPage() {
    return this.route.path === '/areas/tokyo/categories/konkatsu/'
  }

  get isRegionRankingPage() {
    return this.route.name === 'rankings-region' && this.route.params.region
  }

  get isPrefectureRankingPage() {
    return this.route.name.includes('prefecture_rankings') || this.route.name.includes('prefecture-rankings')
  }

  get isAreaGroupRankingPage() {
    return this.route.name.includes('area_group_rankings') || this.route.name.includes('area-group-rankings')
  }

  get isAreaRankingPage() {
    return this.route.path.includes('/area_rankings/')
  }

  get isQuestionnairesPage() {
    return (this.route.name === 'questionnaires-id' || this.route.name === 'questionnaires-id-page') && !this.route.params.id
  }

  get isInformationTopPage() {
    return this.route.name === 'information' || this.route.name === 'information-page'
  }

  get isInformationDetailPage() {
    return this.route.name === 'information-id'
  }

  get isMediumTopPage() {
    return this.route.name === 'medium' || this.route.name === 'medium-page'
  }

  get isMarketInsightsTopPage() {
    return this.route.name === 'market_insights' || this.route.name === 'market-insights-page'
  }

  get isMarketInsightsDetailPage() {
    return this.route.name === 'market_insights-id'
  }

  get isPartyTopPage() {
    return this.isCategoryDetailPage && this.route.params.category === 'party'
  }

  get isAreaPartyPage() {
    return this.route.name.includes('areas') && this.route.params.category === 'party'
  }

  get isCategoryDetailPage() {
    return this.route.name === 'categories' || this.route.name === 'categories-page'
  }

  get isCategoryPage() {
    return this.route.name.includes('categories') && this.route.params.category
  }

  get isFeatureDetailPage() {
    return this.route.name === 'features-events' || this.route.name === 'features-events-page'
  }

  get isFeatureWanttolove() {
    return this.isFeatureDetailPage && this.route.params.feature_tag === 'wanttolove'
  }

  get isFeatureFifties() {
    return this.isFeatureDetailPage && this.route.params.feature_tag === 'fifties'
  }

  get isPromoterDetailPage() {
    return this.route.name === 'promoters' || this.route.name === 'promoters-page'
  }

  get isStaticPage() {
    return this.route.name.includes('static_page')
  }

  get isEventBookmarksPage() {
    return this.route.name === 'event_bookmarks'
  }

  get isEventBookmarksGuestPage() {
    return this.route.name === 'event-bookmarks-guest'
  }

  get isDateEventsPage() {
    return this.route.path.includes('/dates/')
  }

  get isPromoterEventsPage() {
    return this.route.path.includes('/promoters/')
  }

  get isEventDetailPage() {
    return this.route.name === 'events-code'
  }

  get isInternationalizationPage() {
    return this.route.name.includes('-intl')
  }

  // the page has url like /areas/xxx
  get isAreaPrefecturePage() {
    return this.route.name.includes('areas') && this.route.params.prefecture && !this.route.params.area_group && !this.route.params.area
  }

  // the page has url like /areas/xxx/agxxxx
  get isAreaGroupPage() {
    return this.route.name.includes('areas') && this.route.params.prefecture && this.route.params.area_group && !this.route.params.area
  }

  // the page has url like /areas/xxx/agxxxx/yyyy
  get isAreaWithCityOrTownPage() {
    return this.route.name.includes('areas') && this.route.params.prefecture && this.route.params.area_group && this.route.params.area
  }

  // the page has url that contain /areas/xxx
  get isSpecificAreaPage() {
    return this.route.path.includes('/areas') && this.route.params.prefecture
  }

  get isSpecificRegionPage() {
    return this.route.path.includes('/regions') && this.route.params.region
  }

  get isAreasPageWithPromoters() {
    return this.route.name === 'areas-promoters'
  }

  get isSearchWithPrefOrAreaPage() {
    return this.isSearchEventsPage && (this.route.query.prefecture || this.route.query['area[]'])
  }

  get isSimpleAreaPage() {
    return ['areas', 'areas-page'].includes(this.route.name)
  }

  get isMarriagesPage() {
    return this.route.name === 'marriages'
  }

  get isCspPage() {
    // patch: /areas/$prefecture$/csp/$type$
    // this has name = areas, same areas page
    return this.route.path.includes('/csp')
  }

  get isParticipantsPage() {
    return ['participants-profile', 'participants-cl_users'].includes(this.route.name)
  }

  get isParticipantsListPage() {
    return this.route.name === 'participants-cl_users'
  }

  get isClEventsPage() {
    return /^cl_events-/.test(this.route.name)
  }

  get isMatchingEventsPage() {
    return this.route.name === 'cl_events-matching_events'
  }

  get isDatingEventsPage() {
    return this.isClEventsPage && /(dating_events|dating_events-id)$/.test(this.route.name)
  }

  get isGenderOrCompleteParticipantPage() {
    return this.isParticipantsPage && ['gender', 'complete'].includes(this.route.query['step'])
  }

  get isSimpleRegionPage() {
    return ['regions', 'regions-page'].includes(this.route.name)
  }

  get isPurchaseCompletePage() {
    return this.route.name === 'payments-id-method-success' && this.route.params.id && this.route.params.method
  }

  get isPurchasesTopPage() {
    return this.route.name === 'purchases'
  }

  get isPurchaseOptionPage() {
    return this.route.name === 'events-code-tickets-ticket_id-purchase_options'
  }

  get isPurchaseNewPage() {
    return this.route.name === 'events-code-tickets-ticket_id-purchases-new'
  }

  get isGuestPurchasNewPage() {
    return this.route.name === 'events-code-tickets-ticket_id-guest_purchases-new'
  }

  get isAmazonPurchaseNewPage() {
    return this.route.name === 'events-code-tickets-ticket_id-amazon_purchases-new'
  }

  get isAmazonGuestPurchaseNewPage() {
    return this.route.name === 'events-code-tickets-ticket_id-amazon_purchases-guest_new'
  }

  get isPayPayWaitingPage() {
    return this.route.name === 'events-code-tickets-ticket_id-purchases-purchase_id-paypay-waiting'
  }

  get isCompanionNewPage() {
    return this.route.name === 'purchases-id-companions-new'
  }

  get isEventPreviewPage() {
    return this.route.name === 'event_previews'
  }

  get isSpecialPrefecturePartyPage() {
    return (
      this.route.name === 'areas-categories' &&
      this.route.params.category === 'party' &&
      PARTY_PAGE_SPECIAL_PREFECTURES.includes(this.route.params.prefecture)
    )
  }

  get isReceiveTicketPage() {
    return this.route.name === 'check_in_tickets-receive'
  }

  get isAreasInterestsTopPage() {
    return ['areas-interests', 'areas-interests-page'].includes(this.route.name) && !this.route.params.interest
  }

  get isOnlineSpecialPage() {
    return this.route.path.includes('features/vbar') || this.route.path.includes('/features/online_')
  }

  get isPrefInterestsTopPage() {
    return this.isAreasInterestsTopPage && !this.route.params.area_group
  }

  get isAreasCategoriesPage() {
    return ['areas-categories', 'areas-categories-page'].includes(this.route.name)
  }

  get isAreasCategoriesKonkatsuPage() {
    return this.isAreaKonkatsuPage && this.route.params.area
  }

  get isRegionsCategoriesPage() {
    return ['regions-categories', 'regions-categories-page'].includes(this.route.name)
  }

  get isRegionsPageWithInterests() {
    return this.route.name === 'regions-interests' && !this.route.params.interest
  }

  get isRegionsInterestsPage() {
    return ['regions-interests', 'regions-interests-page'].includes(this.route.name)
  }

  get isAreasInterestsPage() {
    return ['areas-interests', 'areas-interests-page'].includes(this.route.name)
  }

  get isDatesPage() {
    return this.route.name === 'dates'
  }

  get isDatesPages() {
    return this.route.name === 'dates-page'
  }

  get isDatesPageWithYear() {
    return !this.route.params.day && !this.route.params.month && this.route.params.year
  }

  get isDatesPageWithYearMonth() {
    return !this.route.params.day && this.route.params.month && this.route.params.year
  }

  get isDatesPageWithDayMonthYear() {
    return this.route.params.day && this.route.params.month && this.route.params.year
  }

  get isDatesPageWithDayMonthYearRegions() {
    return this.isDatesPageWithDayMonthYear && ['dates-regions', 'dates-regions-page'].includes(this.route.name)
  }

  get isDatesPageWithDayMonthYearAreas() {
    return this.isDatesPageWithDayMonthYear && ['dates-areas', 'dates-areas-page'].includes(this.route.name)
  }

  get isDatesPageWithDayMonthYearCategories() {
    return this.isDatesPageWithDayMonthYear && ['dates-categories', 'dates-categories-page'].includes(this.route.name)
  }

  get isDatesPageWithDayMonthYearAreasCategories() {
    return this.isDatesPageWithDayMonthYear && ['dates-areas-categories', 'dates-areas-categories-page'].includes(this.route.name)
  }

  get isDatesPageWithDayMonthYearRegionsCategories() {
    return this.isDatesPageWithDayMonthYear && ['dates-regions-categories', 'dates-regions-categories-page'].includes(this.route.name)
  }

  get isDatesPageWithDayMonthYearFeatures() {
    return this.isDatesPageWithDayMonthYear && ['dates-features-events', 'dates-features-events-page'].includes(this.route.name)
  }

  get isDatesPageWithDayMonthYearAreasFeatures() {
    return this.isDatesPageWithDayMonthYear && ['dates-areas-features-events', 'dates-areas-features-events-page'].includes(this.route.name)
  }

  get isDatesPageWithDayMonthYearRegionsFeatures() {
    return this.isDatesPageWithDayMonthYear && ['dates-regions-features-events', 'dates-regions-features-events-page'].includes(this.route.name)
  }

  get isDatesPageWithYearInterestsBus() {
    return this.isDatesPageWithYear && this.route.name === 'dates-interests' && this.route.params.interest === 'bus'
  }

  get isDatesPageWithYearMonthInterests() {
    return this.isDatesPageWithYearMonth && this.route.name === 'dates-interests' && !this.route.params.interest
  }

  get isDatesPageWithYearInterests() {
    return this.isDatesPageWithYear && this.route.name === 'dates-interests' && !this.route.params.interest
  }

  get isDatesPageWithYearRegionsInterests() {
    return this.isDatesPageWithYear && this.route.name === 'dates-regions-interests' && !this.route.params.interest
  }

  get isDatesPageWithYearMonthRegionsInterests() {
    return this.isDatesPageWithYearMonth && this.route.name === 'dates-regions-interests' && !this.route.params.interest
  }

  get isDatesPageWithYearRegionsPromoters() {
    return this.isDatesPageWithYear && this.route.name === 'dates-regions-promoters'
  }

  get isDatesPageWithDayMonthYearRegionsPromoters() {
    return this.isDatesPageWithDayMonthYear && ['dates-regions-promoters', 'dates-regions-promoters-page'].includes(this.route.name)
  }

  get isDatesPageWithYearPrefectureInterests() {
    return this.isDatesPageWithYear && this.route.name === 'dates-areas-interests' && !this.route.params.area_group && !this.route.params.interest
  }

  get isDatesPageWithYearPrefecturePromoters() {
    return this.isDatesPageWithYear && this.route.name === 'dates-areas-promoters' && !this.route.params.area_group
  }

  get isDatesPageWithYearAreaGroupInterests() {
    return (
      this.isDatesPageWithYear &&
      this.route.name === 'dates-areas-interests' &&
      this.route.params.area_group &&
      !this.route.params.area &&
      !this.route.params.interest
    )
  }

  get isDatesPageWithYearAreaInterests() {
    return this.isDatesPageWithYear && this.route.name === 'dates-areas-interests' && this.route.params.area && !this.route.params.interest
  }

  get isDatesPageWithYearAreaPromoters() {
    return this.isDatesPageWithYear && this.route.name === 'dates-areas-promoters' && this.route.params.area
  }

  get isDatesPageWithDayMonthYearAreaPromoters() {
    return this.isDatesPageWithDayMonthYear && ['dates-areas-promoters', 'dates-areas-promoters-page'].includes(this.route.name)
  }

  get isRegionsPageWithInterestsAnime() {
    return this.route.name === 'regions-interests' && this.route.params.interest === 'game' && this.route.params.sub_interest === 'anime'
  }

  get isRegionsPageWithInterestsSportsFutsal() {
    return this.route.name === 'regions-interests' && this.route.params.interest === 'sports' && this.route.params.sub_interest === 'futsal'
  }

  get isInterestsPageWithActivityAmuse() {
    return this.route.path.includes('/interests/activity/amuse')
  }

  get isRegionsPageWithPromoters() {
    return this.route.name === 'regions-promoters'
  }

  get isAreasPageWithPrefecturePromoters() {
    return this.route.name === 'areas-promoters' && !this.route.params.area_group
  }

  get isAreasPageWithAreaGroupPromoters() {
    return this.route.name === 'areas-promoters' && this.route.params.area_group && !this.route.params.area
  }

  get isAreasPageWithAreaInterestsActivityAmuse() {
    return this.route.name === 'areas-interests-activity' && this.route.params.area && this.route.params.activity_theme === 'amuse'
  }

  get isAreasPageWithAreaPromoters() {
    return ['areas-promoters', 'areas-promoters-page'].includes(this.route.name) && this.route.params.area
  }

  get isAreasPage() {
    return this.route.path.startsWith('/areas/')
  }

  get isEventListPage() {
    return (
      (new RegExp(`^/(${EVENT_LIST_PATHS.join('|')})/`).test(this.route.path) && !this.isSearchDetailPage && !this.isFeaturesTopPage) ||
      this.isSearchEventsFromTopPage
    )
  }

  get isAreaOtherPage() {
    return this.route.name.indexOf('areas') >= 0 && this.route.params.area && this.route.params.area.indexOf('-other') >= 0
  }

  get isDisplayAreaBannerPage() {
    return this.route.name.indexOf('areas') >= 0 || ['prefecture', 'area', 'prefecture[]', 'area[]'].some(key => this.route.query[key])
  }

  get isFeaturePage() {
    return this.isFeatureDetailPage || this.route.query['feature']
  }

  get isGreetingPage() {
    return this.route.name === 'events-code-purchases-purchase_id-greeting'
  }

  get isGreetingPageCompanion() {
    return this.isGreetingPage && this.route.query.companion
  }

  get isSyumiplusPage() {
    return this.route.path.includes('syumiplus')
  }

  get isStaticPromoterPage() {
    return this.route.name.includes('static_page') && STATIC_PROMOTER_PATH.includes(this.route.path)
  }

  get isOrganizerReviewPage() {
    return this.route.name === 'review-organizer' && this.route.params.organizer
  }

  get isMessageDetailsPage() {
    return this.route.name === 'messages-id'
  }

  get isMessagesPage() {
    return this.route.name === 'messages'
  }

  get isCustomSearchByCategoryPage() {
    return this.route.name.includes('areas-custom-search-categories-events') && ['konkatsu', 'party'].includes(this.route.params.category)
  }

  // /path or /path/page/1
  get isFirstPage() {
    return (!this.route.params.page && !this.route.query.page) || parseInt(this.route.params.page) === 1 || parseInt(this.route.query.page) === 1
  }

  //
  // PRIVATE
  //

  get _isNotAllPrefectureAreaGroupArea() {
    return !this.route.params.prefecture && !this.route.params.area_group && !this.route.params.area
  }
}
