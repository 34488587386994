import { useMainStore } from '@/store'
import semver from 'semver'

export const webkitCallback = (callbackName, message = '') => {
  const ua = window.navigator.userAgent.toLowerCase()
  const isMobileNativeApp = ua.indexOf('machiconjapan') > -1
  const isIos = ua.indexOf('iphone') > -1 || ua.indexOf('ipad') > -1 || ua.indexOf('ipod') > -1
  if (isMobileNativeApp && isIos && window.webkit && window.webkit.messageHandlers) {
    if (callbackName in window.webkit.messageHandlers) window.webkit.messageHandlers[callbackName].postMessage(message)
  }
}
export const androidCallback = (callbackName, message = '') => {
  if (window.Android && callbackName in window.Android) window.Android[callbackName](message)
}
export const appsflyerCallback = (eventName, params = {}) => {
  if (typeof window === 'undefined') return
  const mainStore = useMainStore()
  const app_version = mainStore.native_app_version
  if (!app_version) return
  // ネイティブ側のコードによって、受け取れる引数の数が違うため、バージョンで分岐
  const appsflyerSingleArgumentVersion = '2.1.2'
  const eventData = semver.gt(app_version, appsflyerSingleArgumentVersion) ? JSON.stringify({ eventName, params }) : eventName
  webkitCallback('callAppsflyerEvent', eventData)
  androidCallback('callAppsflyerEvent', eventData)
}
