export const OPIX_EVENTS_CHECK_POINTS_ON_TOP_PAGE = [
  {
    class: 'opixTopBanners',
    itemName: 'top banners'
  },
  {
    class: 'opixTopSimpleSearch',
    itemName: 'top simple search'
  },
  {
    class: 'opixAPIRecommendEventsNew',
    itemName: 'AIRecommend_new'
  },
  {
    class: 'opixRecentCheckedEvents',
    itemName: 'recent checked events'
  },
  {
    class: 'opixAttentionEvents',
    itemName: 'attention events'
  },
  {
    class: 'opixTopSpRanking',
    itemName: 'top sp ranking'
  },
  {
    class: 'opixFeatures',
    itemName: 'features'
  },
  {
    class: 'opixCategoryAndTagList',
    itemName: 'category and tag list'
  },
  {
    class: 'opixForBeginner',
    itemName: 'for beginner'
  },
  {
    class: 'opixChooseByAge',
    itemName: 'choose by age'
  },
  {
    class: 'opixRecommendationServices',
    itemName: 'recommendation sevices'
  },
  {
    class: 'opixPublicRelations',
    itemName: 'public ralations'
  },
  {
    class: 'opixCLMobileApps',
    itemName: 'cl mobile apps'
  },
  {
    class: 'opixFooter',
    itemName: 'footer'
  }
]

export const OPIX_EVENTS_CHECK_POINTS_ON_EVENT_DETAIL_PAGE = [
  {
    class: 'opixRecommendAlternativeDate',
    itemName: 'AIRecommend_alternative_date'
  },
  {
    class: 'opixAPIRecommendEvents1New',
    itemName: 'AIRecommend_new'
  },
  {
    class: 'opixParticipantConditions',
    itemName: 'participant conditions'
  },
  {
    class: 'opixEventFlow',
    itemName: 'event flow'
  },
  {
    class: 'opixNews',
    itemName: 'event news'
  },
  {
    class: 'opixEventContent',
    itemName: 'event content'
  },
  {
    class: 'opixEventPlace',
    itemName: 'event place'
  },
  {
    class: 'opixDetailInfos',
    itemName: 'detail informations'
  },
  {
    class: 'opixRequestCustomer',
    itemName: 'request customer'
  },
  {
    class: 'opixCautions',
    itemName: 'cautions'
  },
  {
    class: 'opixProhibitions',
    itemName: 'prohibitions'
  },
  {
    class: 'opixAPIRecommendEvents2New',
    itemName: 'AIRecommend_new'
  },
  {
    class: 'opixRecentCheckedEvents',
    itemName: 'recent checked events'
  },
  {
    class: 'opixOtherKonkatsuEvents',
    itemName: 'other konkatsu events'
  },
  {
    class: 'opixOtherMachiconEvents',
    itemName: 'other machicon events'
  },
  {
    class: 'opixSpecialtyPrefectureEventList',
    itemName: 'specialty prefecture event list'
  },
  {
    class: 'opixSpecialtyKonkatsuEventList',
    itemName: 'specialty konkatsu event list'
  },
  {
    class: 'opixSideContentSp',
    itemName: 'side content sp'
  },
  {
    class: 'opixFeatures',
    itemName: 'features'
  },
  {
    class: 'opixRecommendationServices',
    itemName: 'recommendation services'
  },
  {
    class: 'opixPublicRelations',
    itemName: 'public relations'
  },
  {
    class: 'opixFeatureTags',
    itemName: 'feature tags'
  },
  {
    class: 'opixCategoryAndTagList',
    itemName: 'category and tag list'
  },
  {
    class: 'opixCommonBottomLinks',
    itemName: 'common bottom links'
  },
  {
    class: 'opixClMobileApps',
    itemName: 'cl mobile apps'
  },
  {
    class: 'opixFooterBreadcrumbs',
    itemName: 'footer breadcrumbs'
  },
  {
    class: 'opixFooter',
    itemName: 'footer'
  }
]

export const OPIX_EVENTS_CHECK_POINTS_ON_TOP_PAGE_ON_PC = [
  {
    class: 'opixAPIRecommendEventsNew',
    itemName: 'AIRecommend_new'
  },
  {
    class: 'opixSearchFromArea',
    itemName: 'search from area'
  },
  {
    class: 'opixRecentCheckedEvents',
    itemName: 'recent checked events'
  },
  {
    class: 'opixFeatures',
    itemName: 'features'
  },
  {
    class: 'opixKonkatsuPickUp',
    itemName: 'konkatsu pick up'
  },
  {
    class: 'opixMachiconPickUp',
    itemName: 'machicon pick up'
  },
  {
    class: 'opixAreaPickUp',
    itemName: 'area pick up'
  },
  {
    class: 'opixContentsMenu',
    itemName: 'contents menu'
  },
  {
    class: 'opixRanking',
    itemName: 'ranking'
  },
  {
    class: 'opixKonkatsuRanking',
    itemName: 'konkatsu ranking'
  },
  {
    class: 'opixRecommendationServices',
    itemName: 'recommendation services'
  },
  {
    class: 'opixAdsTieUps',
    itemName: 'ads tie ups'
  },
  {
    class: 'opixNotices',
    itemName: 'notices'
  },
  {
    class: 'opixRegisterLink',
    itemName: 'register link'
  },
  {
    class: 'opixCommonBottomLinks',
    itemName: 'common bottom links'
  },
  {
    class: 'opixFooter',
    itemName: 'footer'
  }
]

export const OPIX_EVENTS_CHECK_POINTS_ON_EVENT_LIST_PAGE = [
  {
    class: 'opixAPIRecommendEventsNew',
    itemName: 'AIRecommend_related_search_results_new'
  },
  {
    class: 'opixAPIRecommendEvents1New',
    itemName: 'AIRecommend_related_recent_check_new'
  },
  {
    class: 'opixHeaderBreadCrumbs',
    itemName: 'header breadcrumbs'
  },
  {
    class: 'opixEventListHeader',
    itemName: 'event list header'
  },
  {
    class: 'opixEvenList',
    itemName: 'event list'
  },
  {
    class: 'opixRankingPopularEvents',
    itemName: 'ranking popular events'
  },
  {
    class: 'opixRecentCheckedEvents',
    itemName: 'recent check events'
  },
  {
    class: 'opixActivityList',
    itemName: 'activity list'
  },
  {
    class: 'opixKonkatsuPickup',
    itemName: 'konkatsu pickup'
  },
  {
    class: 'opixKonkatsuRanking',
    itemName: 'konkatsu ranking'
  },
  {
    class: 'opixKonkatsuFeatureTagBanners',
    itemName: 'konkatsu feature tag banners'
  },
  {
    class: 'opixIveryList',
    itemName: 'ivery list'
  },
  {
    class: 'opixCategoryAndTagList',
    itemName: 'category and tag list'
  },
  {
    class: 'opixFeatures',
    itemName: 'features'
  },
  {
    class: 'opixRecommendationServices',
    itemName: 'recommendation services'
  },
  {
    class: 'opixPublicRelations',
    itemName: 'public relations'
  },
  {
    class: 'opixAdsTieUps',
    itemName: 'ads tie ups'
  },
  {
    class: 'opixClMobileApps',
    itemName: 'cl mobile apps'
  },
  {
    class: 'opixFooterBreadCrumbs',
    itemName: 'footer breadcrumbs'
  },
  {
    class: 'opixFooter',
    itemName: 'footer'
  }
]
export const OPIX_EVENTS_CHECK_POINTS_ON_EVENT_BOOKMARKS = [
  {
    class: 'opixAPIRecommendEventsNew',
    itemName: 'AIRecommend_related_bookmarks_new'
  },
  {
    class: 'opixAPIRecommendEvents1New',
    itemName: 'AIRecommend_personalized_new'
  }
]

export const OPIX_EVENTS_CHECK_POINTS_RECOMMEND_PERSONALIZED = [
  {
    class: 'opixAPIRecommendPersonalizedNew',
    itemName: 'AIRecommend_personalized_new'
  }
]

export const OPIX_EVENTS_CHECK_POINTS_RECOMMEND_SALE = [
  {
    class: 'opixAPIRecommendEventSale',
    itemName: 'AIRecommend_event_sale'
  }
]

export const OPIX_CHECK_POINTS_ON_MESSAGE_PAGE = [
  {
    class: 'opixClMobileApps',
    itemName: 'cl mobile apps'
  }
]

export const OPIX_ONLINE_EVENT_TABS = { all: 'all', offline: 'real', online: 'online' }

export const OPIX_LOAD_WAIT_TIME = 300 // ms
