/**
 * Composable function to determine the A/B test pattern (A or B) based on provided configuration.
 *
 * This composable checks if the current time is within the configured A/B test period.
 * If within the period, it determines the user's pattern (A or B) based on a random number.
 * The random number can be persisted across sessions using cookies or stored temporarily in the store.
 *
 * @param config - Configuration object for the A/B test.
 * @param config.startTime - (Optional) The start time of the A/B test period.
 * @param config.endTime - (Optional) The end time of the A/B test period.
 * @param config.bPatternRatio - The percentage ratio (0-100) of users assigned to pattern B.
 * @param config.keepSession - Boolean indicating whether to persist the user's pattern across sessions.
 *
 * @returns An object containing two reactive boolean refs:
 * - `isAPattern`: Indicates if the user is assigned to pattern A.
 * - `isBPattern`: Indicates if the user is assigned to pattern B.
 *
 * @example
 * - Add config to abTestConfig.ts:
 * const config = {
 *   test_name: 'AB_TEST_NAME',
 *   startTime: '2025-01-01',
 *   endTime: '2025-12-31',
 *   bPatternRatio: 20, // 20% of users will be assigned to pattern B
 *   keepSession: true
 * }
 *
 * - Use the composable in a component:
 * import { useABTest } from '@/composables/abtest/useABTest'
 * import { config } from '@/composables/abtest/abTestConfig'
 * const { isAPattern, isBPattern } = useABTest(config)
 */

import moment from 'mj-moment'
import { useMainStore } from '@/store'
import ABTestClientIDService from '@/utils/ABTestClientIDService'
import type { ABTestConfig } from './abTestConfig'

export function isABTestPeriod(config: ABTestConfig): boolean {
  const now = moment()

  if (!config.startTime && !config.endTime) return true
  if (config.startTime && !config.endTime) return now.isAfter(config.startTime)
  if (!config.startTime && config.endTime) return now.isBefore(config.endTime)
  return now.isBetween(config.startTime, config.endTime)
}

export function useABTest(config: ABTestConfig) {
  const store = useMainStore()

  const isBPattern = ref(false)
  const isAPattern = ref(false)

  if (!isABTestPeriod(config)) {
    return { isAPattern, isBPattern }
  }

  const determinePattern = (randomNumber: number) => {
    const normalizedRandom = randomNumber % 100
    if (normalizedRandom <= config.bPatternRatio) {
      isBPattern.value = true
    } else {
      isAPattern.value = true
    }

    return { isAPattern, isBPattern }
  }

  // Get ab_test random number from cookie if keepSession is true
  if (config.keepSession) {
    const randomNumber = ABTestClientIDService.getClientId(store)
    return determinePattern(randomNumber)
  }

  // Get ab_test_random_number from store state if keepSession is false
  const storedRandomNumber = store.$state.ab_test_random_number
  if (storedRandomNumber !== null) {
    return determinePattern(storedRandomNumber)
  }

  // Generate a new random number and set it in the store
  const newRandomNumber = Math.floor(Math.random() * 100)
  store.SET_STATE({
    key: 'ab_test_random_number',
    value: newRandomNumber
  })

  return determinePattern(newRandomNumber)
}
