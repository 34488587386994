import { useMainStore } from '@/store'

const SET_STATE = data => {
  const mainStore = useMainStore()
  const state = mainStore.$state
  state[data.key] = data.value
}
// TODO: This mutation is not good because it able to change state disorderly.
const SET_STATE_BY_PATH = payload => {
  // payload: { path: ['path', 'to', 'state'], value: 'value' }
  const mainStore = useMainStore()
  let state = mainStore.$state
  payload.path.forEach((path, index) => {
    if (index === payload.path.length - 1) {
      state[path] = payload.value
    } else {
      if (!state[path]) state[path] = {}
      state = state[path]
    }
  })
}

const BOOKMARK_EVENT = id => {
  const mainStore = useMainStore()
  const state = mainStore.$state
  if (!state.bookmark_events) state.bookmark_events = []
  const index = state.bookmark_events.indexOf(id)

  if (index !== -1) {
    state.bookmark_events.splice(index, 1)
  } else {
    state.bookmark_events.push(id)
  }
}

const MODAL = data => {
  const mainStore = useMainStore()
  const state = mainStore.$state

  state.modal.name = data ? data.name : null
  state.modal.payload = data ? data.payload : null
}

const FLASH_MESSAGE = payload => {
  const mainStore = useMainStore()
  const state = mainStore.$state

  if (!payload) {
    state.flash_message = null
  } else {
    const targetPath = /\/$/.test(payload.target_path) ? payload.target_path : `${payload.target_path}/`
    const flashMessage = { type: payload.type || 'info', message: payload.message, messages: payload.messages, target_path: targetPath }
    state.flash_message = flashMessage
  }
}

const LAYOUT_DATA = payload => {
  const mainStore = useMainStore()
  const state = mainStore.$state

  state.layout_data[payload.type] = payload.data
}

const SEARCH_DATA = payload => {
  if (Object.keys(payload).length === 0) return

  const mainStore = useMainStore()
  const state = mainStore.$state

  if (Object.prototype.hasOwnProperty.call(payload, 'events')) {
    state.search_page.full_path = payload.full_path
    state.search_page.total_count = payload.total_count
    state.search_page.count_from = payload.count_from
    state.search_page.has_event_dates = payload.has_event_dates
    state.search_page.events = payload.events
    state.search_page.base_event = payload.base_event
    state.search_page.top_banner = payload.top_banner
    state.search_page.top_banners = payload.top_banners
    state.search_page.total_event_online_count = payload.total_event_online_count
    state.search_page.total_event_offline_count = payload.total_event_offline_count
    state.search_page.search_params = payload.search_params
    state.search_page.couplink_event_1on1_store = payload.couplink_event_1on1_store
    state.search_page.pr_events = payload.pr_events
    state.search_page.result_updated_at = Date.now()
    state.search_page.search_by_close_area_links = payload.search_by_close_area_links
  }

  if (Object.prototype.hasOwnProperty.call(payload, 'page_details')) {
    state.search_page.page_details = payload.page_details || {}
    state.search_page.search_conditions = payload.search_conditions || {}
    state.search_page.page_details_updated_at = Date.now()
  }

  if (Object.prototype.hasOwnProperty.call(payload, 'subareas')) {
    state.search_page.subareas = payload.subareas
    state.search_page.subareas_updated_at = Date.now()
  }

  if (payload.ads) {
    state.search_page.ads = payload.ads || {}
    state.search_page.ads_updated_at = Date.now()
  }
}

export { SET_STATE, SET_STATE_BY_PATH, BOOKMARK_EVENT, MODAL, FLASH_MESSAGE, LAYOUT_DATA, SEARCH_DATA }
