export default class ABTestClientIDService {
  static COOKIE_KEY = 'mj_ab_uid'
  static COOKIE_MAX_AGE = 2 * 365 * 24 * 60 * 60 // 2 years
  static COOKIE_OPTIONS = `Max-Age=${ABTestClientIDService.COOKIE_MAX_AGE};HttpOnly;Path=/`

  // Call this at the end of nuxtServerInit if you want to use AB test
  static setupABTest(store, nuxtApp) {
    const storedClientId = ABTestClientIDService.getClientId(store)
    if (import.meta.server && !storedClientId) {
      const clientId = ABTestClientIDService.generateClientId()
      nuxtApp.ssrContext.event.res.setHeader('Set-Cookie', `${ABTestClientIDService.COOKIE_KEY}=${clientId};${ABTestClientIDService.COOKIE_OPTIONS}`)
      store.SET_STATE({ key: 'ssr_cookie', value: `${store.ssr_cookie}; ${ABTestClientIDService.COOKIE_KEY}=${clientId}` })
    }
  }

  static generateClientId() {
    return Math.round((Date.now() + Math.random()) * 1000)
  }

  static getClientId(store) {
    return store.ssrCookie[ABTestClientIDService.COOKIE_KEY]
  }

  static isOddPattern(store) {
    return ABTestClientIDService.getClientId(store) % 2 !== 0
  }

  static is25PercentPattern(store) {
    return ABTestClientIDService.getClientId(store) % 4 === 0
  }
}
